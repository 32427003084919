import authStore from './authStore';
import userStore from './userStore';
import sessionsStore from './sessionsStore';
import messageStore from './messageStore';
import settingsStore from './settingsStore';
import videoStore from './videoStore';

export default {
  authStore,
  userStore,
  sessionsStore,
  messageStore,
  settingsStore,
  videoStore,
};
