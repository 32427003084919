import React, { useState, useMemo, useCallback, FormEvent } from 'react';
import { Grid, TextField, Button, Card } from '@material-ui/core';
import { API } from '../../../api';
import { useRouteMatch } from 'react-router-dom';
import { useCallbackWithLoading } from '../../../hooks/use-callback-with-loading';
import styles from '../UserManagement.module.scss';

const ForgotPassword = () => {
  const [data, setData] = useState({ password: '', againPassword: '' });
  const match = useRouteMatch<{ token: string }>();
  const [errorMsg, setErrorMsg] = useState(false);
  const [sucessMsg, setSucessMsg] = useState(false);
  const [forgotPass, resetPending] = useCallbackWithLoading(API.resetPassword);
  const error = useMemo(() => !!data.againPassword && data.password !== data.againPassword, [data]);
  const errorLength = useMemo(() => data.password.length < 8 && data.againPassword.length < 8, [data]);
  const handleDataChange = useCallback(({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
    setData((d) => ({ ...d, [currentTarget.id]: currentTarget.value }));
  }, []);
  const canSubmit = useMemo(() => !!data.password && data.password === data.againPassword && !errorLength, [data]);

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      setErrorMsg(false);
      setSucessMsg(false);
      e.preventDefault();
      if (!canSubmit) {
        return;
      }
      try {
        await forgotPass(match.params.token, data.password);
        setSucessMsg(true);
      } catch (error) {
        setData((d) => ({ ...d, password: '' }));
        setErrorMsg(true);
      }
    },
    [canSubmit, forgotPass, match.params.token, data.password]
  );

  return (
    <Grid container direction="column" justify="center" alignItems="center" className={styles.root}>
      <form onSubmit={handleSubmit}>
        <Card elevation={0}>
          <Grid container direction="column" justify="center" alignItems="center">
            <TextField
              className={styles.inputs}
              required
              value={data.password}
              id="password"
              label="Password"
              type="password"
              autoComplete="new-password"
              onChange={handleDataChange}
            />
            <TextField
              className={styles.inputs}
              required
              value={data.againPassword}
              id="againPassword"
              label="Repeat Password"
              type="password"
              autoComplete="new-password-repeat"
              onChange={handleDataChange}
            />
            <br />
            <Button className={styles.inputs} type="submit" variant="contained" disabled={!canSubmit || resetPending}>
              Save New Password
            </Button>
          </Grid>
        </Card>
        {errorLength ? <div className={styles.error}>Password need to be at least 8 caracters long</div> : null}
        {!errorLength && error ? <div className={styles.error}>Passwords does not match</div> : null}
        {errorMsg ? <div className={styles.error}>Change password failed</div> : null}
        {sucessMsg ? <div className={styles.success}>Change password successful</div> : null}
      </form>
    </Grid>
  );
};

export default ForgotPassword;
