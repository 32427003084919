import { FormControl, Select, InputLabel, Button, Grid } from '@material-ui/core';
import MaterialTable from 'material-table';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API } from '../../../api';
import { User } from '../../../models/general';
import { Header } from '../../../models/tables';
import messageStore from '../../../stores/messageStore';
import settingsStore from '../../../stores/settingsStore';
import userStore from '../../../stores/userStore';
import CarchupaPaper from '../../UI/papper/CarchupaPaper';
import styles from './UsersSelect.module.scss';

interface UsersMenuItemProps {
  users: User[];
  selectedRows: User[];
  setUserSelection: (users: User[]) => void;
  toggle: () => void;
}

function UsersMenuItem(props: UsersMenuItemProps) {
  const { users, selectedRows, setUserSelection, toggle } = props;
  const { t } = useTranslation();

  const headCells: Header[] = [
    {
      field: 'ID',
      title: 'ID',
      sortable: true,
    },
    {
      field: 'firstName',
      title: t('firstName'),
      sortable: true,
    },
    {
      field: 'lastName',
      title: t('lastName'),
      sortable: true,
    },
    {
      field: 'username',
      title: t('username'),
      sortable: true,
    },
  ];

  const handleCheckboxClick = (event: any, rowData: User) => {
    console.log('rowData', event, rowData);
  };

  return (
    <>
      <MaterialTable
        title={t('Users')}
        columns={headCells}
        data={users}
        options={{
          search: true,
          selection: true,
          padding: 'dense',
          pageSize: 10,
          initialPage: 0,
          selectionProps: (rowData: User) => {
            console.log('selectionProps');
            return {
              checked: selectedRows.map((user) => user.ID).includes(rowData.ID),
              onClick: (event: any, rowData: any) => handleCheckboxClick(event, rowData),
            };
          },
        }}
        actions={[
          {
            tooltip: t('Select'),
            icon: 'check',
            onClick: (evt, data: any[]) => {
              toggle();
            },
          },
        ]}
        components={{
          Container: (props) => <CarchupaPaper {...props} type="column" parentStyle={styles.paper} childStyle={styles.table} />,
        }}
        onSelectionChange={(rows) => {
          setUserSelection(rows);
        }}
      />
    </>
  );
}

interface UsersSelectProps {
  usersDropdownTitle: string;
}

const UsersSelect = (props: UsersSelectProps) => {
  const { selectedUsers } = userStore;
  // console.log("selectedUsers", selectedUsers);
  // TODO filtering by project / state
  // const { selectedProject, selectedState } = settingsStore;
  // console.log(selectedProject, selectedState);
  const { t } = useTranslation();
  const [rows, setRows] = useState<User[]>([]);
  const [selectedRows, setSelectedRows] = useState<User[]>([]);
  const [open, setOpen] = React.useState(false);

  const toggle = () => {
    saveUserSelection(selectedRows);
    setOpen(!open);
  };

  const handleChange = (selectedUsers: User[]) => {
    console.log('handleChange', selectedUsers);
    setSelectedRows(selectedUsers);
  };

  const saveUserSelection = (selectedUsers: User[]) => {
    console.log('saveUserSelection', selectedUsers);
    userStore.updateSelectedUsers(selectedUsers);
  };

  const loadUserList = useCallback(async () => {
    const response = await API.getUsers();
    if (response) {
      setRows(response.data.users.filter((user: User) => !!user?.isActive));
      messageStore.snackbar({
        message: `${t('Projects loaded')}`,
        type: 'info',
      });
    } else {
      messageStore.snackbar({
        message: `${t('Failed to fetch project list')}`,
        type: 'error',
      });
    }
  }, [t]);

  const test = (event: any) => {
    console.log('test', event);
    event.stopPropagation();
    event.preventDefault();
    handleChange([]);
    // toggle();
  };

  useEffect(() => {
    console.log('UserSelect loaded');
    loadUserList();
  }, [loadUserList]);

  return (
    <FormControl className={styles.formControl}>
      <InputLabel shrink id="project-select-label">
        User
      </InputLabel>
      <Select
        labelId="project-select-label"
        id="project-select"
        displayEmpty
        open={open}
        classes={{
          root: styles.root,
        }}
        onClose={() => {
          console.log('onClose', selectedRows);
          saveUserSelection(selectedRows);
          setOpen(false);
        }}
        onOpen={() => {
          setOpen(true);
        }}
        renderValue={(selected) => {
          if (!!selectedUsers?.length) {
            if (selectedUsers?.length > 2) {
              return <em>Selected {selectedUsers.length} users</em>;
            } else {
              return <em>{selectedUsers.map((user) => user.username).join(',')}</em>;
            }
          } else {
            return <em>{props.usersDropdownTitle}</em>;
          }
        }}
      >
        <UsersMenuItem setUserSelection={handleChange} selectedRows={selectedRows} users={rows} toggle={toggle} />
        <Grid container direction="row" justify="flex-end" alignItems="center" className={styles.gap}>
          {/* <Button
            variant="contained"
            type="button"
            color="primary"
            onClick={test}
          >
            Clear
          </Button> */}
          {/*           <Button
            variant="contained"
            color="primary"
            type="button"
            disableElevation={true}
            disableFocusRipple={true}
            disableRipple={true}
            onClick={() => {
              if (false) {
                messageStore.snackbar({
                  message: `${t("Please select at least one user")}`,
                  type: "info",
                });
              } else {
                toggle();
              }
            }}
          >
            Select
          </Button> */}
        </Grid>
      </Select>
    </FormControl>
  );
};

export default inject('userStore', 'messageStore', 'settingsStore')(observer(UsersSelect));
