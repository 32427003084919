import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import styles from './UserCarchupa.module.scss';
import CarchupaPaper from '../../../components/UI/papper/CarchupaPaper';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import { RouteComponentProps, useRouteMatch } from 'react-router-dom';
import { API } from '../../../api';
import messageStore from '../../../stores/messageStore';
import { useTranslation } from 'react-i18next';
import VideoItem from '../../../components/UI/video-item/VideoItem';
import VideoList from '../../../components/UX/video-list/VideoList';
import { PaymentSingleUser, User } from '../../../models/general';
import ProjectSelect from '../../../components/UX/project-select/ProjectSelect';
import YearMonthPicker from '../../../components/UX/system/YearMonthPicker';
import MaterialTable from 'material-table';
import { Header } from '../../../models/tables';
import settingsStore from '../../../stores/settingsStore';
import moment, { Moment } from 'moment';

interface Props extends RouteComponentProps {}

const UserCarchupa = (props: Props) => {
  const initialRender = useRef(true);
  const [userData, setuserData] = React.useState<User>();

  const match = useRouteMatch<{ userId: string }>();
  const { t } = useTranslation();

  const { selectedRowCount, selectedProject } = settingsStore;
  const [rows, setRows] = React.useState<PaymentSingleUser[]>([]);
  const datelimit = new Date();
  datelimit.setDate(31);
  const [selectedStartDate, handleStartDateChange] = React.useState(moment(datelimit));
  const [selectedEndDate, handleEndDateChange] = React.useState(moment(datelimit));

  const headCells: Header[] = [
    {
      field: 'pID',
      title: t('ID'),
      sortable: true,
    },
    {
      field: 'username',
      title: t('username'),
      sortable: true,
    },
    {
      field: 'IBAN',
      title: t('IBAN'),
      sortable: false,
    },
    {
      field: 'taxPercentage',
      title: t('Tax'),
      sortable: false,
    },
    {
      field: 'socialSecurityNumber',
      title: t('SSN'),
      sortable: false,
    },
    {
      field: 'totalEarnings.coinCount',
      title: t('Coins'),
      sortable: true,
    },
    {
      field: 'totalEarnings.blueberryCount',
      title: t('Bluberries'),
      sortable: true,
    },
    {
      field: 'totalEarnings.cherryCount',
      title: t('Cherries'),
      sortable: true,
    },
    {
      field: 'totalEarnings.lemonCount',
      title: t('Lemons'),
      sortable: true,
    },
    {
      field: 'totalEarnings.strawberryCount',
      title: t('Strawberries'),
      sortable: true,
    },
    {
      field: 'totalEarnings.diamondCount',
      title: t('Diamonds'),
      sortable: true,
    },
    {
      field: 'totalValue',
      title: t('Total Value'),
      sortable: true,
    },
  ];

  const loadUsers = useCallback(async () => {
    const response = await API.getUser(Number(match.params.userId));
    if (response) {
      if (!!response.data.userData) {
        setuserData(response.data.userData);
      }
    } else {
      messageStore.snackbar({
        message: t('Failed fetching users'),
        type: 'error',
      });
    }
  }, [match.params.userId, t]);

  const loadUsersPayments = useCallback(
    async (startDate?, endDate?, projectId?) => {
      setRows([]);
      const response = await API.getSingleUserPayments(
        Number(match.params.userId),
        // moment(selectedStartDate).startOf('month').unix(),
        startDate,
        endDate,
        projectId
      );
      if (typeof response.data !== 'string') {
        messageStore.snackbar({
          message: `${'User payments loaded'}`,
          type: 'success',
        });
        setRows(response.data);
      } else {
        messageStore.snackbar({
          message: t('No users payments data'),
          type: 'success',
        });
      }
    },
    [match.params.userId, t]
  );

  const selectStartDateCallbackFunction = (date: Moment) => {
    handleStartDateChange(date);
  };

  const selectEndDateCallbackFunction = (date: Moment) => {
    handleEndDateChange(date);
  };

  const lastPendingVideo = useMemo(() => {
    if (!!userData?.allVideoData && userData?.allVideoData?.length) {
      return userData?.allVideoData.filter((video) => video.isUploaded && !video.isApproved && !video.isOnVaisala).pop();
    }
  }, [userData]);

  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadUsersPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      loadUsersPayments(moment(selectedStartDate).startOf('month').unix(), selectedEndDate.unix(), selectedProject);
    }
  }, [selectedStartDate, selectedEndDate, selectedProject, loadUsersPayments]);

  return (
    <SimpleLayout passedStyles={styles.root}>
      <CarchupaPaper type="column" parentStyle={styles.generalInfo} childStyle={''} title={t('Account details')}>
        <div>
          <div className={styles.fullName}>
            {userData?.firstName ?? ''} {userData?.lastName ?? ''} (ID: {userData?.ID ?? ''})
          </div>
          <div className={styles.username}>
            {t('Username')}:&nbsp;
            {userData?.username ?? ''}
          </div>
          <div className={styles.email}>{userData?.email ?? ''}</div>
          <br />
          <div className={styles.videoStats}>
            <span>
              {t('Pending')}:&nbsp;
              {userData?.videoCount.pendingVideoCount ?? ''}
            </span>
            <span>
              {t('Uploaded')}:&nbsp;
              {userData?.videoCount.uploadedVideoCount ?? ''}
            </span>
            <span>
              {t('Recording')}:&nbsp;
              {userData?.videoCount.recordingVideoCount ?? ''}
            </span>
          </div>
        </div>
      </CarchupaPaper>

      <CarchupaPaper type="column" parentStyle={styles.payments} childStyle={''}>
        <span>IBAN: {userData?.IBAN ? userData?.IBAN : 'Not defined' ?? ''}</span>
        <span>SWIFT/BIC: {userData?.swiftbic ? userData?.IBAN : 'Not defined' ?? ''}</span>
        <span>TIN: {userData?.tin ? userData?.IBAN : 'Not defined' ?? ''}</span>
        <span>SSN: {userData?.socialSecurityNumber ? userData?.socialSecurityNumber : 'Not defined' ?? ''}</span>
        <span>Tax: {userData?.taxPercentage ? `${userData?.taxPercentage}%` : 'Not defined' ?? ''}</span>
        <span>Holder Offer: {userData?.receiveHolderOffer ? 'Yes' : 'No'}</span>
        <span>Lense Offer: {userData?.receiveLenseOffer ? 'Yes' : 'No'}</span>
      </CarchupaPaper>

      <CarchupaPaper type="column" parentStyle={styles.pendingVideos} childStyle={''} title={t('Last pending video')}>
        {!!lastPendingVideo ? <VideoItem video={lastPendingVideo} {...props}></VideoItem> : <span>No pending videos</span>}
      </CarchupaPaper>

      <CarchupaPaper type="column" parentStyle={styles.approvedVideos} childStyle={''} title={t('Last uploaded video')}>
        {!!userData?.lastVideoData ? <VideoItem video={userData.lastVideoData} {...props}></VideoItem> : <span>No aproved videos</span>}
      </CarchupaPaper>

      <CarchupaPaper type="column" parentStyle={styles.allVideos} childStyle={''} title={t('User videos')}>
        {!!userData?.allVideoData ? (
          <VideoList videoList={userData?.allVideoData} pageSizeOptions={[5, 10, 15]} {...props} />
        ) : (
          <span>No videos</span>
        )}
      </CarchupaPaper>
      <CarchupaPaper type="row" parentStyle={styles.payments}>
        <ProjectSelect></ProjectSelect>
        <YearMonthPicker parentCallback={selectStartDateCallbackFunction} limit={selectedEndDate.toDate() as Date} />
        <YearMonthPicker parentCallback={selectEndDateCallbackFunction} limit={datelimit} />
      </CarchupaPaper>
      {selectedStartDate && rows.length > 0 ? (
        <MaterialTable
          title={t('User Payments')}
          columns={headCells}
          data={rows}
          options={{
            search: true,
            selection: false,
            padding: 'default',
            pageSize: selectedRowCount,
          }}
          components={{
            Container: (props) => <CarchupaPaper {...props} type="column" parentStyle={styles.payments} childStyle={styles.table} />,
          }}
          onChangeRowsPerPage={(rows) => {
            settingsStore.updateSelectedRowCount(rows);
          }}
        />
      ) : (
        <CarchupaPaper type="row" parentStyle={styles.payments}>
          <div>No users payments data</div>
        </CarchupaPaper>
      )}
    </SimpleLayout>
  );
};

export default UserCarchupa;
