
import { observable, computed, action, decorate } from 'mobx';
import { User } from '../models/general';

export class UserStore {
    users = observable<User>([]);
    selectedUsers = observable<User>([]);
    selectedUser: User | undefined;

    updateUser(value: User | undefined) {
        this.selectedUser = value;
    }

    updateUsers(newUsers: User[]) {
        this.users.replace(newUsers);
    }

    updateSelectedUsers(newUsers: User[]) {
        this.selectedUsers.replace(newUsers);
    }

    get getUsers(): User[] {
        return this.users;
    }

    get getUser(): User | undefined {
        return this.selectedUser;
    }

    get getUserId(): number | undefined {
        return this.selectedUser?.ID;
    }

    get getSelectedUsers(): User[] {
        return this.selectedUsers;
    }


    selectedUserTablePage: number = 0;

    updateSelectedUserTablePage(selectedUserTablePage: number) {
        this.selectedUserTablePage = selectedUserTablePage;
    }
    get getSelectedUserTablePage(): number {
        return this.selectedUserTablePage;
    }


}


decorate(UserStore, {
    users: observable,
    selectedUser: observable,
    selectedUserTablePage: observable,

    updateUser: action,
    updateUsers: action,
    updateSelectedUserTablePage: action,

    getUsers: computed,
    getUser: computed,
    getSelectedUserTablePage: computed,
});


export default new UserStore();
