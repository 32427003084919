import React, { PropsWithChildren } from "react";
import styles from "./SimpleLayout.module.scss";
import { Scrollbars } from "react-custom-scrollbars";
import Toolbar from "@material-ui/core/Toolbar";
import GlobalLoader from "../global-loader/GlobalLoader";
import { inject, observer } from "mobx-react";
import settingsStore from "../../../stores/settingsStore";

interface SimpleLayoutProps {
  passedStyles: string;
}

const SimpleLayout = (props: PropsWithChildren<SimpleLayoutProps>) => {
  const { children, passedStyles } = props;
  const { globalLoading } = settingsStore;
  return (
    <>
      <Toolbar />
      <GlobalLoader></GlobalLoader>
      <Scrollbars style={{ width: "100%", height: globalLoading ? "calc(100% - 4px)" : "100%" }}>
        <div className={`${styles.simpleLayout} ${passedStyles}`}>
          {children}
        </div>
      </Scrollbars>
    </>
  );
};

export default inject("settingsStore")(observer(SimpleLayout));

