import React, { useState, useMemo, useCallback, FormEvent } from 'react';
import { Grid, TextField, Button, Card } from '@material-ui/core';
import { API } from '../../../api';
import { useCallbackWithLoading } from '../../../hooks/use-callback-with-loading';
import styles from '../UserManagement.module.scss';

const ForgotPasswordRequest = () => {
  const [data, setData] = useState({ email: '' });
  const [resetPasswordRequest] = useCallbackWithLoading(
    API.resetPasswordRequest
  );
  const canSubmit = useMemo(() => !!data.email, [data]);
  const [error, setError] = useState(false);
  const [sucess, setSucess] = useState(false);

  const handleDataChange = useCallback(
    ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
      setError(false);
      setData((d) => ({ ...d, [currentTarget.id]: currentTarget.value }));
    },
    []
  );

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!canSubmit) {
        return;
      }
      setError(false);
      setSucess(false);
      try {
        await resetPasswordRequest(data.email);
        setSucess(true);
      } catch (error) {
        setError(true);
        setData((d) => ({ email: '' }));
      }
    },
    [canSubmit, resetPasswordRequest, data.email]
  );

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={styles.root}
    >
      <form onSubmit={handleSubmit}>
        <Card elevation={0}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <TextField
              className={styles.inputs}
              required
              id="email"
              label="Email"
              onChange={handleDataChange}
            />
            <br />
            <Button
              className={styles.inputs}
              type="submit"
              variant="contained"
              disabled={!canSubmit}
            >
              Request new password
            </Button>
            <br />
          </Grid>
        </Card>
        {error ? (
          <div className={styles.error}>
            The provided email not match any user.
          </div>
        ) : null}
        {sucess ? (
          <div className={styles.success}>Email has been sent.</div>
        ) : null}
      </form>
    </Grid>
  );
};

export default ForgotPasswordRequest;
