import { FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API } from '../../../api';
import { CarchupaCountry } from '../../../models/general';
import messageStore from '../../../stores/messageStore';
import settingsStore from '../../../stores/settingsStore';
import styles from './StateSelect.module.scss';

interface Props {
  allStatePlaceholder: string;
}

const StateSelect = (props: Props) => {
  const { allStatePlaceholder } = props;
  const { selectedState } = settingsStore;
  const { t } = useTranslation();
  const [rows, setRows] = useState<CarchupaCountry[]>([]);

  const handleChange = (event: { target: { value: any } }) => {
    settingsStore.updateSelectedState(event.target.value);
  };

  const loadStateList = useCallback(async () => {
    const response = await API.getStateList();
    if (response) {
      setRows(response.data.countries);
      // messageStore.snackbar({message: `${t('States loaded')}`, type: 'info'});
    } else {
      messageStore.snackbar({
        message: `${t('Failed to fetch state list')}`,
        type: 'error',
      });
    }
  }, [t]);

  useEffect(() => {
    console.log('StateSelect loaded');
    loadStateList();
    return () => {
      settingsStore.updateSelectedState(null);
    }
  }, [loadStateList]);

  return (
    <FormControl className={styles.formControl}>
      <InputLabel shrink id="state-select-label">
        State
      </InputLabel>
      <Select labelId="state-select-label" id="state-select" value={!!selectedState ? selectedState : ''} onChange={handleChange} displayEmpty>
        <MenuItem value="">
          <em>{allStatePlaceholder}</em>
        </MenuItem>
        {rows.map((item: CarchupaCountry) => (
          <MenuItem key={item.ID} value={item.ID}>
            {item.name} ({item.ID})
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default inject('settingsStore', 'messageStore')(observer(StateSelect));
