import React, { useCallback, useEffect } from 'react';
import styles from './Users.module.scss';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import { inject, observer } from 'mobx-react';
import { User } from '../../../models/general';
import { Button, LinearProgress } from '@material-ui/core';
import { API } from '../../../api';
import userStore from '../../../stores/userStore';
import { CsvBuilder } from 'filefy';
import { RouteComponentProps } from 'react-router-dom';
import { ROUTES } from '../../../parameters/routes';
import MaterialTable from 'material-table';
import { Header } from '../../../models/tables';
import messageStore from '../../../stores/messageStore';
import { useTranslation } from 'react-i18next';
import CarchupaPaper from '../../../components/UI/papper/CarchupaPaper';
import ProjectSelect from '../../../components/UX/project-select/ProjectSelect';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import CarchupaDialog from '../../../components/UX/dialog/CarchupaDialog';
import settingsStore from '../../../stores/settingsStore';
import moment from 'moment';

interface UsersProps extends RouteComponentProps {}

const Users = (props: UsersProps) => {
  const { history } = props;
  const { t } = useTranslation();
  const { selectedProject, selectedRowCount } = settingsStore;
  const { users, selectedUserTablePage } = userStore;
  const [dense, setDense] = React.useState(false);
  const [dialogDelete, setDialogDelete] = React.useState(false);
  const [selectedUsers, setSelectedUsers] = React.useState<number[]>([]);
  const [loading, setloading] = React.useState(true);

  const headCells: Header[] = [
    {
      field: 'ID',
      title: 'ID',
      sortable: true,
    },
    {
      field: 'firstName',
      title: t('firstName'),
      sortable: true,
    },
    {
      field: 'lastName',
      title: t('lastName'),
      sortable: true,
    },
    {
      field: 'username',
      title: t('username'),
      sortable: true,
    },
    {
      field: 'email',
      title: t('Email'),
      sortable: true,
      hidden: true
    },
    {
      field: 'IBAN',
      title: t('IBAN'),
      sortable: true,
      hidden: true
    },
    {
      field: 'socialSecurityNumber',
      title: t('Social Security Number'),
      sortable: true,
      hidden: true
    },
    {
      field: 'taxPercentage',
      title: t('Tax Percentage'),
      sortable: true,
      hidden: true
    },
    {
      field: 'receiveHolderOffer',
      title: t('Receive Holder Offer'),
      sortable: true,
      hidden: true
    },
    {
      field: 'receiveLenseOffer',
      title: t('Receive Lense Offer'),
      sortable: true,
      hidden: true
    },
    {
      field: 'videoCount.pendingVideoCount',
      title: t('Pending'),
      sortable: true,
    },
    {
      field: 'videoCount.uploadedVideoCount',
      title: t('Uploaded'),
      sortable: true,
    },
    {
      field: 'videoCount.recordingVideoCount',
      title: t('Recording'),
      sortable: true,
    },
    {
      field: 'userInfo',
      title: '',
      sortable: false,
      render: (rowData: User) => (
        <Button onClick={(event) => selectUserProfile(event, rowData)}>
          <AccountCircleIcon />
        </Button>
      ),
    },
    {
      field: 'menu',
      title: '',
      sortable: false,
      render: (rowData: User) => (
        <Button onClick={(event) => selectUserSessions(event, rowData)}>
          <DirectionsCarIcon />
        </Button>
      ),
    },
  ];

  const loadUsers = useCallback(async () => {
    setloading(true);
    const response = await API.getUsers(selectedProject);

    if (response) {
      setloading(false);
      userStore.updateUsers(response.data.users);
      messageStore.snackbar({ message: t('Users loaded'), type: 'success' });
    } else {
      setloading(false);
      messageStore.snackbar({
        message: t('Failed fetching users'),
        type: 'error',
      });
    }
  }, [t, selectedProject]);

  const onCloseDialogDelete = useCallback(
    async (status: boolean) => {
      setDialogDelete(false);
      if (status) {
        const response = await API.deleteUsers(selectedUsers);

        if (response) {
          loadUsers();
        } else {
          messageStore.snackbar({
            message: t('Failed fetching users'),
            type: 'error',
          });
        }
      }
    },
    [loadUsers, selectedUsers, t]
  );

  useEffect(() => {
    userStore.updateUser(undefined);
    loadUsers();
  }, [loadUsers]);

  const selectUserSessions = (event: React.MouseEvent<unknown>, user: User) => {
    userStore.updateUser(user);
    userStore.updateSelectedUsers([user]);
    history.push(ROUTES.videos);
  };

  const selectUserProfile = (event: React.MouseEvent<unknown>, user: User) => {
    userStore.updateUser(user);
    userStore.updateSelectedUsers([user]);
    history.push(ROUTES.createUsersInfoUrl(user.ID));
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  return (
    <SimpleLayout passedStyles={styles.root}>
      <CarchupaPaper type="row" parentStyle={styles.paper}>
        <ProjectSelect></ProjectSelect>
      </CarchupaPaper>
      {!!users.filter((user) => !!user?.isActive).length ? (
        <>
          <MaterialTable
            title={t('Users')}
            columns={headCells}
            data={users.filter((user) => !!user?.isActive)}
            options={{
              search: true,
              exportButton: true,
              selection: true,
              exportAllData: true,
              padding: dense ? 'dense' : 'default',
              pageSize: selectedRowCount,
              initialPage:
                Math.ceil(users.filter((user) => !!user?.isActive).length / selectedRowCount) >= selectedUserTablePage ? selectedUserTablePage : 0,
            }}
            actions={[
              {
                tooltip: t('Remove All Selected Users'),
                icon: 'delete',
                onClick: (evt, data: any[]) => {
                  setDialogDelete(true);
                },
              },

              {
                position: 'toolbarOnSelect',
                icon: 'save_alt',
                tooltip: 'Export selected rows',
                onClick: (e, rowData: any) => {
                  const fileName = 'Users' + moment().format('YYYYMMDD');
                  const builder = new CsvBuilder(fileName + '.csv');
                  builder
                    .setColumns(headCells.map((columnDef) => columnDef.title))
                    .addRows(
                      rowData.map((rowData: any) => {
                        return headCells.map((columnDef) =>{ 
                          const split =  columnDef.field.split('.');
                          if (split.length > 1) {                            
                            return rowData[split[0]][split[1]]
                          } else {
                            return rowData[columnDef.field]
                          }
                         });
                      })
                    )
                    .exportFile();
                },
              },

              // {
              //   tooltip: t('Download All Selected Users'),
              //   icon: 'get_app',
              //   onClick: (evt, data: any[]) => {
              //     alert(`${t('You want to download data for')} ${data.length} ${t('users')}`);
              //     console.debug('TODO DOWNLOAD USER CALL');
              //   },
              // },
            ]}
            components={{
              Container: (props) => <CarchupaPaper {...props} type="column" parentStyle={styles.paper} childStyle={styles.table} />,
            }}
            onSelectionChange={(rows) => {
              setSelectedUsers(rows.map((el) => el.ID));
            }}
            onChangeRowsPerPage={(rows) => {
              settingsStore.updateSelectedRowCount(rows);
            }}
            onChangePage={(rows) => {
              userStore.updateSelectedUserTablePage(rows);
            }}
          />
          <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label={t('Dense padding')} />
        </>
      ) : loading ? (
        <LinearProgress />
      ) : (
        <CarchupaPaper type="row" parentStyle={styles.paper}>
          <div>No users</div>
        </CarchupaPaper>
      )}
      <CarchupaDialog
        open={dialogDelete}
        handleClose={onCloseDialogDelete}
        message={t('Do you want to delete selected users?')}
        title={t('Confirm Delete')}
      />
    </SimpleLayout>
  );
};

export default inject('userStore', 'settingsStore', 'messageStore')(observer(Users));
