import React from 'react';
import {  BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ROUTES } from '../../../parameters/routes';
import Home from '../../../routes/core/home/Home';
import Login from '../../../routes/user-management/login/Login';
import ForgotPassword from '../../../routes/user-management/password-reset/ForgotPassword';
import ForgotPasswordRequest from '../../../routes/user-management/request-password-reset/ForgotPasswordRequest';


const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <Route path={ROUTES.login} exact={true} component={Login} />
        <Route path={ROUTES.passwordReset} component={ForgotPassword} />
        <Route path={ROUTES.passwordRequest} exact={true} component={ForgotPasswordRequest} />
        <Route path={ROUTES.home} component={Home} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
