import React, { useEffect, useState, useCallback, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { API } from '../../../api';
import { RouteComponentProps } from 'react-router-dom';
import { ROUTES } from '../../../parameters/routes';

import Pagination from '@material-ui/lab/Pagination';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import { inject, observer } from 'mobx-react';
import messageStore from '../../../stores/messageStore';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import CarchupaPaper from '../../../components/UI/papper/CarchupaPaper';
import ProjectItem from '../../../components/UI/projects-item/ProjectsItem';
import Map from '../../../components/UX/map/Map';

import DateRangeSelect from '../../../components/UX/date-range-select/DateRangeSelect';
import StateSelect from '../../../components/UX/state-select/StateSelect';
import settingsStore from '../../../stores/settingsStore';
import moment from 'moment';

import { CarchupaProject } from '../../../models/general';

import styles from './Projects.module.scss';

interface ProjectsProps extends RouteComponentProps {}

const Projects = (props: ProjectsProps) => {
  const { history } = props;
  const [loading, setloading] = useState(true);
  const { selectedState, selectedPeriod } = settingsStore;
  const [searched, setSearched] = useState('');

  const [projects, setProjects] = useState<CarchupaProject[]>([]);
  const { t } = useTranslation();

  const loadProjectsList = useCallback(
    async (selectedState) => {
      setloading(true);
      settingsStore.updateGlobalLoading(true);
      const projectRequest: any = {
        startDate: (function (startDate) {
          if (!!startDate) {
            const date = new Date(startDate);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            return moment(date).unix();
          } else {
            return undefined;
          }
        })(selectedPeriod?.startDate),
        endDate: (function (endDate) {
          if (!!endDate) {
            const date = new Date(endDate);
            date.setHours(23);
            date.setMinutes(59);
            date.setSeconds(59);
            return moment(date).unix();
          } else {
            return undefined;
          }
        })(selectedPeriod?.endDate),
        limit: 1000,
        offset: 0,
      };

      const response = await API.getProjectList(projectRequest);
      if (response) {
        setloading(false);
        settingsStore.updateGlobalLoading(false);
        !!selectedState
          ? setProjects(response.data.projects.filter((project: any) => project.countryID === selectedState))
          : setProjects(response.data.projects);

        messageStore.snackbar({
          message: t('Projects loaded'),
          type: 'success',
        });
      } else {
        setloading(false);
        settingsStore.updateGlobalLoading(false);
        messageStore.snackbar({
          message: t('Failed fetching projects'),
          type: 'error',
        });
      }
    },
    [selectedPeriod]
  );

  const handleClick = (projectID: any) => {
    history.push(ROUTES.createProjectsInfoUrl(projectID));
  };

  const searchedProjects = () => {
    return projects.filter((project) => {
      const searchedProject = searched.toLowerCase();
      return project.name.toLowerCase().includes(searchedProject);
    });
  };

  // PAGINATION
  //
  const [currentPage, setCurrentPage] = useState(1);
  const [projectsPerPage, setProjectsPerPage] = useState(2);

  // Logic for displaying current pages
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentPageProjects = searchedProjects().slice(indexOfFirstProject, indexOfLastProject);

  const renderCurrentPageProjects = currentPageProjects.map((project: any, index: any) => {
    return (
      <ProjectItem
        key={project.ID}
        projectName={project.name}
        projectDescription={project.description}
        parentStyle={styles.projectItem}
        handleClick={() => handleClick(project.ID)}
        collectable={project.collectablesCount}
        totalCollected={!!project.collectablesCount.collectedCount ? project.collectablesCount.collectedCount : 0}
        totalValue={!!project.collectablesCount.totalValue ? project.collectablesCount.totalValue : 0}
        showSelectProjectButton={true}
      >
        <Map projects={project} projectID={project.ID} />
      </ProjectItem>
    );
  });

  // Logic for displaying page numbers

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(searchedProjects().length / projectsPerPage); i++) {
    pageNumbers.push(i);
  }
  const pageNumber = pageNumbers.length;

  const handleClickPagination = (event: any) => {
    setCurrentPage(Number(event.target.textContent));
  };

  //
  // PAGINATION

  const handleCreateProject = () => {
    history.push(ROUTES.addProject);
  };

  useEffect(() => {
    loadProjectsList(selectedState);
  }, [selectedState]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searched]);

  return (
    <SimpleLayout passedStyles={styles.root}>
      <CarchupaPaper type="row">
        <StateSelect allStatePlaceholder={'All states'} />
        <DateRangeSelect />
        <TextField
          className={styles.search}
          id="standard-basic"
          label="Search..."
          type="text"
          placeholder="Search..."
          onChange={(event: ChangeEvent<HTMLInputElement>) => setSearched(event.target.value)}
        />
      </CarchupaPaper>
      <CarchupaPaper childStyle={styles.parent}>
        <div className={styles.renderedItems}>{renderCurrentPageProjects}</div>
        <Pagination className={styles.pagination} count={pageNumber} hidePrevButton hideNextButton onClick={handleClickPagination} shape="rounded" />
      </CarchupaPaper>
      <Button className={styles.button} variant="contained" color="primary" type="submit" onClick={handleCreateProject}>
        Add Project
      </Button>
    </SimpleLayout>
  );
};

export default inject()(observer(Projects));
