import React, { useCallback, useEffect, useMemo } from 'react';
import styles from './VideoList.module.scss';
import { inject, observer } from 'mobx-react';
import MaterialTable from 'material-table';
import { Header } from '../../../models/tables';
import { useTranslation } from 'react-i18next';
import CarchupaPaper from '../../UI/papper/CarchupaPaper';
import VideoItem from '../../UI/video-item/VideoItem';
import { Checkbox, createStyles, FormControlLabel, Grid, makeStyles, Typography } from '@material-ui/core';
import videoStore from '../../../stores/videoStore';
import VideoActionSelect from '../video-action-select/VideoActionSelect';
import { VideoData } from '../../../models/video';
import { RouteComponentProps } from 'react-router-dom';
import settingsStore from '../../../stores/settingsStore';

interface Props extends RouteComponentProps {
  videoList: VideoData[];
  pageSizeOptions: number[];
}

const VideoList = (props: Props) => {
  const { videoList, pageSizeOptions } = props;
  const [videoRowItems, setVideoRowItems] = React.useState(3);
  const { t } = useTranslation();

  const { selectedVideos, selectedVideoTablePage } = videoStore;
  const { selectedRowCount } = settingsStore;

  const useStyles = makeStyles(() =>
    createStyles({
      videoItem: {
        width: `${100 / videoRowItems}%`,
      },
    })
  );

  const haveSelectedVideos = useMemo(() => !!selectedVideos.length, [selectedVideos.length]);

  const allVideosSelected = useMemo(() => selectedVideos.length === videoList.length, [selectedVideos.length, videoList.length]);

  const classes = useStyles();

  const headCells: Header[] = [
    {
      field: 'ID',
      title: t('ID'),
      sortable: true,
    },
    {
      field: 'name',
      title: t('Video Name'),
      sortable: true,
    },
    {
      field: 'createdAt',
      title: t('Created'),
      sortable: true,
    },
    {
      field: 'isFinished',
      title: t('Finished'),
      sortable: true,
      render: (rowData: VideoData) => (!!rowData.isFinished ? 'Yes' : 'No'),
    },
    {
      field: 'collectables.coinCount',
      title: 'C',
      sortable: true,
    },
    {
      field: 'collectables.strawberryCount',
      title: 'S',
      sortable: true,
    },
    {
      field: 'collectables.diamondCount',
      title: 'D',
      sortable: true,
    },
  ];

  const handleChangeAllVideos = (event: { target: { checked: React.SetStateAction<boolean> } }) => {
    console.log('event.target.checked', event.target.checked);
    if (event.target.checked) {
      videoStore.updateVideos(videoList.map((video) => video.ID));
    } else {
      videoStore.updateVideos([]);
    }
  };

  const getChunks = useCallback(
    (array: any[]) => {
      // console.log('getChunks')
      const final = [];
      for (var i = 0; i < Math.ceil(array.length / videoRowItems); i++) {
        const chunks = array.slice(i * videoRowItems, i * videoRowItems + videoRowItems);
        const item: any = { items: chunks };
        final.push(item);
      }
      return final;
    },
    [videoRowItems]
  );

  useEffect(() => {
    setVideoRowItems(3);
  }, []);

  const rows = useMemo(() => {
    const chunks = getChunks(videoList);
    // console.log(chunks);
    return chunks;
  }, [getChunks, videoList]);

  // console.log(
  //   Math.ceil(rows.length / selectedRowCount) >= selectedVideoTablePage,
  //   Math.ceil(rows.length / selectedRowCount),
  //   selectedVideoTablePage
  // );

  return (
    <MaterialTable
      columns={headCells}
      title={t('Videos')}
      data={rows}
      options={{
        search: false,
        padding: 'default',
        pageSize: selectedRowCount,
        initialPage: Math.ceil(rows.length / selectedRowCount) >= selectedVideoTablePage ? selectedVideoTablePage : 0,
        pageSizeOptions: pageSizeOptions,
      }}
      components={{
        Toolbar: (materialprops: any) =>
          haveSelectedVideos ? (
            <div className={`${styles.selectedVideos}`}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Typography variant="h6" component="h2">
                  {selectedVideos.length} row(s) selected Search
                </Typography>

                <VideoActionSelect />
              </Grid>
            </div>
          ) : null,
        Header: (materialprops: any) => (
          <tbody>
            <tr>
              <th colSpan={videoRowItems} className={`${styles.customHeader}`}>
                {!!rows.length ? (
                  <Grid container direction="row" justify="space-between" alignItems="center">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={allVideosSelected}
                          onChange={handleChangeAllVideos}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Select All"
                    />
                  </Grid>
                ) : null}
              </th>
            </tr>
          </tbody>
        ),
        Body: (materialprops: any) => {
          // console.log(" Bodyprops", JSON.stringify(props.renderData));
          return (
            <tbody>
              {materialprops.renderData.map((videoList: any) => {
                return (
                  <tr className={styles.videoRow} key={Math.random()}>
                    {videoList.items.map((video: VideoData) => (
                      <td className={`${styles.videoItem} ${classes.videoItem}`} key={video.ID}>
                        <VideoItem video={video} {...props}></VideoItem>
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          );
        },
        Container: (props: any) => <CarchupaPaper {...props} type="column" parentStyle={styles.paper} childStyle={styles.table} />,
      }}
      onChangeRowsPerPage={(rows) => {
        settingsStore.updateSelectedRowCount(rows);
      }}
      onChangePage={(rows) => {
        videoStore.updateSelectedVideoTablePage(rows);
      }}
    />
  );
};

export default inject('videoStore', 'settingsStore')(observer(VideoList));
