import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import styles from './ProjectsItemOverview.module.scss';

interface ProjectItemProps {
  projectName: string;
  projectDescription: string;
  handleClick?: () => void;
  parentStyle?: string;
  children?: any;
  collectable?: any;
  totalCollected: number;
  totalCount: number;
  totalValue: number;
  showSelectProjectButton?: boolean;
}

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#3f51b5',
    },
  })
)(LinearProgress);

const ProjectItem = (props: ProjectItemProps) => {
  const { projectName, projectDescription, handleClick, parentStyle, children, totalCollected, totalCount, totalValue } = props;

  return (
    <div className={`${styles.container} ${parentStyle}`}>
      <div>
        <div>{children}</div>
        <div className={styles.header}>
          <div>
            <div className={styles.title} onClick={handleClick}>
              {projectName}
            </div>
            <div className={styles.projectDescription}>{projectDescription}</div>
          </div>
        </div>
      </div>

      <div className={styles.collectables}>
        <div className={styles.totals}>
          <div>Total collected: {totalCollected}</div>
          <div>Total value: {totalValue}</div>
        </div>
        <BorderLinearProgress variant="determinate" value={totalCollected && totalCount ? (totalCollected / totalCount) * 100 : 0} />

        <Button className={styles.button} variant="contained" color="primary" type="submit" onClick={handleClick}>
          View Project
        </Button>
      </div>
    </div>
  );
};

export default ProjectItem;
