import React, { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './Video.module.scss';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ExploreIcon from '@material-ui/icons/Explore';
import CarchupaPaper from '../../../components/UI/papper/CarchupaPaper';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import { useRouteMatch } from 'react-router-dom';
import { API } from '../../../api';
import messageStore from '../../../stores/messageStore';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from '@material-ui/core';
import { useCallbackWithLoading } from '../../../hooks/use-callback-with-loading';
import VideoPlayer from '../../../components/UX/player/Player';
import { VideoData } from '../../../models/video';
import { saveAs } from 'file-saver';
import moment from 'moment';
import settingsStore from '../../../stores/settingsStore';
import { inject, observer } from 'mobx-react';
import { getVideoStatus } from '../../../logic/get-video-status';

interface Props {}

const Video = (props: Props) => {
  const { t } = useTranslation();
  const match = useRouteMatch<{ videoId: string }>();
  const [video, setvideo] = React.useState<VideoData>();
  const [validated, setValidated] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState({ message: '' });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const videoStatus = useMemo(() => getVideoStatus(video), [video]);

  const loadVideo = useCallback(async () => {
    //if not download data
    const response = await API.getVideo(match.params.videoId);
    if (response) {
      if (!!response.data) {
        setvideo(response.data.videoData);
        // console.log(video, "video");
      }
    } else {
      messageStore.snackbar({
        message: t('Failed fetching videos'),
        type: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.videoId, t, video]);

  const showGPX = useCallback(async () => {
    if (!!video) {
      const response = await API.getGPX(video.ID);
      if (response) {
        let blob = new Blob([response], { type: 'text/xml' });
        saveAs(blob, `GPX-${video.ID}.gpx`);
        /*  let url = URL.createObjectURL(blob);
      window.open(url);
      URL.revokeObjectURL(url); */

        /* var win = window.open();
      if (win) {
        win.document.write('<iframe src="data:text/xml,'+encodeURIComponent(response) + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
      } */
      }
    }
  }, [video]);

  const downloadVideo = useCallback(
    async (videoID: number) => {
      settingsStore.updateGlobalLoading(true);

      const getLink = await API.getExportedVideo(videoID);
      const response = await API.getVideoFile(getLink.data.URLs[0].URL);
      if (response) {
        saveAs(response, `Crowdcupa-Video-${moment().format('YYYY-MM-DD')}-${getLink.data.URLs[0].ID}`);
      }
      settingsStore.updateGlobalLoading(false);
    },
    [video]
  );

  const [validateVideo] = useCallbackWithLoading(API.validateVideo);

  const handleDataChange = useCallback(({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    setData((d: any) => ({ ...d, [currentTarget.id]: currentTarget.value }));
  }, []);

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      try {
        if (video) {
          setOpen(false);
          const response = await validateVideo(validated, video.ID, data.message);
          if (response) {
            // sessionStorage.refreshSessions(loadVideoList);
          }
          messageStore.snackbar({
            message: `${t('Video updated')}`,
            type: 'success',
          });
        }
      } catch (error) {
        messageStore.snackbar({
          message: `${t('Video update failed')}`,
          type: 'error',
        });
        console.error(error);
      }
    },
    [data.message, t, validateVideo, validated, video]
  );

  useEffect(() => {
    loadVideo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SimpleLayout passedStyles={styles.root}>
      {!!video ? (
        <>
          <CarchupaPaper type="column" parentStyle={''} childStyle={''} title={t('Video details')}>
            <div className={styles.generalInfo}>
              <div className={styles.videoInfo}>
                <div className={styles.videoInfoRow}>
                  <span>VideoID: {video.ID}</span>
                  <span>|</span>
                  <span>{video.createdAt}</span>
                </div>
                <div className={styles.videoInfoRow}>
                  <span>{video.name}</span>
                </div>

                <div className={styles.videoInfoRow}>
                  {!!video.deviceOS ? (
                    <>
                      <span>{video.deviceName}</span>
                      <span>|</span>
                    </>
                  ) : null}
                  {!!video.deviceOS ? (
                    <>
                      <span>{video.deviceOS}</span>
                      <span>|</span>
                    </>
                  ) : null}

                  {!!video.appVersion ? (
                    <>
                      <span>{video.appVersion}</span>
                      <span>|</span>
                    </>
                  ) : null}
                  {!!video.deviceOS ? <span>{video.environment}</span> : null}
                </div>
                <div className={styles.videoInfoRow}>
                  Status: {videoStatus}
                </div>
              </div>

              <div className={styles.videoPlayer}>{video.URL ? <VideoPlayer videoSrc={video.URL} bigPlayer={true} /> : null}</div>

              <div className={styles.videoActions}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    showGPX();
                  }}
                >
                  <div className={styles.actionButtons}>
                    <ExploreIcon />
                    <span>GPX</span>
                  </div>
                </Button>

                <div className={styles.actionButtons}>
                  <Button
                    color="primary"
                    onClick={() => {
                      handleClickOpen();
                      setValidated(false);
                      setData({ message: t('bad_response') });
                    }}
                  >
                    <ThumbDownIcon />
                  </Button>
                  <div className={styles.line}></div>
                  <Button
                    color="secondary"
                    onClick={() => {
                      handleClickOpen();
                      setValidated(true);
                      setData({ message: t('ok_response') });
                    }}
                  >
                    <ThumbUpIcon />
                  </Button>
                </div>

                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    downloadVideo(video.ID);
                    //window.open(video.URL, "_blank");
                  }}
                >
                  <div className={styles.actionButtons}>
                    <CloudDownloadIcon />
                    <span>Download</span>
                  </div>
                </Button>
              </div>
            </div>
          </CarchupaPaper>

          <CarchupaPaper type="column" parentStyle={''} childStyle={styles.collectablesData}>
            <div className={styles.collectablesInfo}>Coins: {video.collectablesData.coinCount}</div>
            <div className={styles.line}></div>
            <div className={styles.collectablesInfo}>Blueberry: {video.collectablesData.blueberryCount}</div>
            <div className={styles.line}></div>
            <div className={styles.collectablesInfo}>Cherry: {video.collectablesData.cherryCount}</div>
            <div className={styles.line}></div>
            <div className={styles.collectablesInfo}>Lemon:{video.collectablesData.lemonCount}</div>
            <div className={styles.line}></div>
            <div className={styles.collectablesInfo}>Strawberry: {video.collectablesData.strawberryCount}</div>
            <div className={styles.line}></div>
            <div className={styles.collectablesInfo}>Diamond: {video.collectablesData.diamondCount}</div>
          </CarchupaPaper>
        </>
      ) : null}
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">{t('Response')}</DialogTitle>

          <DialogContent>
            <DialogContentText></DialogContentText>

            <TextField
              id="message"
              label={t('Message to show user')}
              multiline
              onChange={handleDataChange}
              rows={4}
              defaultValue={validated ? t('ok_response') : t('bad_response')}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {t('Cancel')}
            </Button>
            <Button type="submit" onClick={handleClose} color="secondary">
              {validated ? t('Approve') : t('Reject')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </SimpleLayout>
  );
};

export default inject('settingsStore')(observer(Video));
