import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import CarchupaPaper from '../../../components/UI/papper/CarchupaPaper';
import MaterialTable from 'material-table';
import * as Yup from 'yup';
import { API } from '../../../api';
import { Header } from '../../../models/tables';
import messageStore from '../../../stores/messageStore';
import settingsStore from '../../../stores/settingsStore';

import styles from './Superadmin.module.scss';
import adminStore from '../../../stores/adminStore';
import { Button, LinearProgress } from '@material-ui/core';
import CarchupaDialog from '../../../components/UX/dialog/CarchupaDialog';
import { Field, Form, Formik } from 'formik';
import { useCallbackWithLoading } from '../../../hooks/use-callback-with-loading';

interface SubmitValues {
  firstName: string;
  lastName: string;
  email: string;
  username: string;
}

const Superadmin = () => {
  const { t } = useTranslation();
  const { selectedRowCount } = settingsStore;
  const { admins, selectedAdminTablePage } = adminStore;
  const [dialogDelete, setDialogDelete] = React.useState(false);
  const [selectedAdmins, setSelectedAdmins] = React.useState<number[]>([]);
  const [loading, setloading] = React.useState(true);
  const [add, sendPending] = useCallbackWithLoading(API.addAdmin);

  const initialValues = {
    firstName: '',
    lastName: '',
    username: '',
    email: '',
  };

  const validate = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    username: Yup.string().required('Required'),
    email: Yup.string().email().required('Required'),
  });

  const headCells: Header[] = [
    {
      field: 'ID',
      title: 'ID',
      sortable: true,
    },
    {
      field: 'firstName',
      title: t('firstName'),
      sortable: true,
    },
    {
      field: 'lastName',
      title: t('lastName'),
      sortable: true,
    },
    {
      field: 'username',
      title: t('username'),
      sortable: true,
    },
    {
      field: 'createdAt',
      title: t('Created At'),
      sortable: true,
    },
  ];

  const loadAdmins = useCallback(async () => {
    setloading(true);
    const response = await API.getAdminsList();
    if (response) {
      setloading(false);
      adminStore.updateAdmins(response.data.admins);
      messageStore.snackbar({ message: t('Admins loaded'), type: 'success' });
    } else {
      setloading(false);
      messageStore.snackbar({
        message: t('Failed fetching admins'),
        type: 'error',
      });
    }
  }, [t]);

  const onCloseDialogDelete = useCallback(
    async (status: boolean) => {
      setDialogDelete(false);
      if (status) {
        const response = await API.deleteAdmins(selectedAdmins);

        if (response) {
          loadAdmins();
        } else {
          messageStore.snackbar({
            message: t('Failed fetching admins'),
            type: 'error',
          });
        }
      }
    },
    [loadAdmins, selectedAdmins, t]
  );

  const addAdmin = useCallback(
    async (values: SubmitValues, setSubmitting: (isSubmitting: boolean) => void, resetForm) => {
      const { firstName, lastName, username, email } = values;
      await add(firstName, lastName, username, email)
        .then((res) => {
          if (res.code === 2000) {
            loadAdmins();
            messageStore.snackbar({
              message: `${t('Admin added')}`,
              type: 'info',
            });
          } else if (res.code === 2001) {
            loadAdmins();
            messageStore.snackbar({
              message: res.message,
              type: 'info',
            });
          }
          resetForm({});
        })
        .catch((err) => {
          messageStore.snackbar({
            message: err.response.data.message,
            type: 'error',
          });
        });
      setSubmitting(false);
    },
    [add, t, loadAdmins]
  );

  useEffect(() => {
    adminStore.updateAdmins([]);
    loadAdmins();
  }, [loadAdmins]);

  return (
    <SimpleLayout passedStyles={styles.root}>
      {admins.length !== 0 ? (
        <MaterialTable
          title={t('Admin list')}
          columns={headCells}
          data={admins.filter((admin) => !admin?.isSuper)}
          options={{
            search: true,
            selection: true,
            pageSize: selectedRowCount,
            initialPage:
              Math.ceil(admins.filter((admin) => !admin?.isSuper).length / selectedRowCount) >= selectedAdminTablePage ? selectedAdminTablePage : 0,
          }}
          actions={[
            {
              tooltip: t('Remove All Selected Users'),
              icon: 'delete',
              onClick: (evt, data: any[]) => {
                setDialogDelete(true);
              },
            },
          ]}
          components={{
            Container: (props) => <CarchupaPaper {...props} type="column" parentStyle={styles.paper} childStyle={styles.table} />,
          }}
          onSelectionChange={(rows) => {
            setSelectedAdmins(rows.map((el) => el.ID));
          }}
          onChangeRowsPerPage={(rows) => {
            settingsStore.updateSelectedRowCount(rows);
          }}
          onChangePage={(rows) => {
            adminStore.updateSelectedAdminTablePage(rows);
          }}
        />
      ) : loading ? (
        <LinearProgress />
      ) : (
        <CarchupaPaper type="row" parentStyle={styles.paper}>
          <div>No admins</div>
        </CarchupaPaper>
      )}
      <CarchupaDialog
        open={dialogDelete}
        handleClose={onCloseDialogDelete}
        message={t('Do you want to delete selected users?')}
        title={t('Confirm Delete')}
      />
      <CarchupaPaper title={t('Add New Admin')} type="column" parentStyle={styles.paper}>
        <div className={styles.formContainer}>
          <Formik
            initialValues={initialValues}
            validateOnMount={true}
            onSubmit={(values: any, { setSubmitting, resetForm }: any) => {
              setSubmitting(true);
              addAdmin(values, setSubmitting, resetForm);
            }}
            validationSchema={validate}
          >
            {({ errors, touched, isSubmitting, setFieldValue }: any) => (
              <Form className={styles.adminForm}>
                <div className={styles.fieldsContainer}>
                  <Field type="text" name="firstName" placeholder="First Name" disabled={isSubmitting} />
                  <Field type="text" name="lastName" placeholder="Last Name" disabled={isSubmitting} />
                  <Field type="text" name="username" placeholder="User Name" disabled={isSubmitting} />
                  <Field type="email" name="email" placeholder="Email address" disabled={isSubmitting} />
                </div>

                {errors.title && touched.title ? <div>{errors.title}</div> : null}

                <div className={styles.submitButton}>
                  <Button variant="contained" color="primary" type="submit" disabled={isSubmitting || sendPending} className={styles.submitButton}>
                    Add admin
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </CarchupaPaper>
    </SimpleLayout>
  );
};

export default inject()(observer(Superadmin));
