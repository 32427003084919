
import { observable, computed, action, decorate } from 'mobx';

export class VideoStore {

    selectedVideoType: string | null = 'isUploaded';

    updateSelectedVideoType(selectedVideoType: string | null) {
        this.selectedVideoType = selectedVideoType;
    }
    get getSelectedVideoType(): string | null {
        return this.selectedVideoType;
    }

    ///// 

    readonly selectedVideos = observable<number>([]);

    updateSelectedVideos(selectedVideos: number[]) {
        this.selectedVideos.replace(selectedVideos);
    }

    addSelectedVideo(selectedVideoId: number) {
        const deepCopy = JSON.parse(JSON.stringify(this.selectedVideos));
        if (!deepCopy.includes(selectedVideoId)) {
            deepCopy.push(selectedVideoId)
        }
        this.updateSelectedVideos(deepCopy);
    }

    removeSelectedVideo(selectedVideoId: number) {
        const deepCopy = JSON.parse(JSON.stringify(this.selectedVideos));
        if (deepCopy.includes(selectedVideoId)) {
            deepCopy.splice(deepCopy.indexOf(selectedVideoId), 1)
        }
        this.updateSelectedVideos(deepCopy);
    }

    updateVideos(videoIDList: number[]) {
        this.updateSelectedVideos(videoIDList);
    }

    get getSelectedVideos(): number[] {
        return this.selectedVideos;
    }


    selectedVideoTablePage: number = 0;

    updateSelectedVideoTablePage(selectedVideoTablePage: number) {
        this.selectedVideoTablePage = selectedVideoTablePage;
    }
    get getSelectedVideoTablePage(): number {
        return this.selectedVideoTablePage;
    }
}


decorate(VideoStore, {
    selectedVideoType: observable,
    selectedVideos: observable,
    selectedVideoTablePage: observable,

    updateSelectedVideoType: action,
    updateSelectedVideos: action,
    addSelectedVideo: action,
    removeSelectedVideo: action,
    updateSelectedVideoTablePage: action,

    getSelectedVideoType: computed,
    getSelectedVideos: computed,
    getSelectedVideoTablePage: computed,
});


export default new VideoStore();
