import React from 'react';
import ReactPlayer from 'react-player'
import styles from './Player.module.scss';

interface Props {
  videoSrc: string;
  bigPlayer?: boolean;
}

const VideoPlayer = (props: Props) => {
  const { videoSrc, bigPlayer } = props;
  return (
    <div className={!!bigPlayer ?  styles.videoBigPlayer : styles.videoPlayer}>
      <ReactPlayer
          url={videoSrc}
          className='react-player'
          light ={true}
          playing={false}
          controls
          width='100%'
          height='100%'
        />
    </div>
  );
};

export default VideoPlayer;
