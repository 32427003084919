import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import MaterialTable from 'material-table';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import { Header } from '../../../models/tables';
import { API } from '../../../api';
import settingsStore from '../../../stores/settingsStore';
import messageStore from '../../../stores/messageStore';
import { ROUTES } from '../../../parameters/routes';
import { RouteComponentProps } from 'react-router-dom';
import Map from '../../../components/UX/map/Map';
import VideoList from '../../../components/UX/video-list/VideoList';
import CarchupaPaper from '../../../components/UI/papper/CarchupaPaper';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import DateRangeSelect from '../../../components/UX/date-range-select/DateRangeSelect';
import StateSelect from '../../../components/UX/state-select/StateSelect';
import { CarchupaProject, LeaderboardRequest } from '../../../models/general';
import { VideoData, VideoRequest } from '../../../models/video';
import ProjectItemOverview from '../../../components/UI/projects-item-overview/ProjectsItemOverview';
import Pagination from '@material-ui/lab/Pagination';

import styles from './Overview.module.scss';

interface OverviewProps extends RouteComponentProps {}

const Overview = (props: OverviewProps) => {
  const { history } = props;
  const { t } = useTranslation();

  const [projects, setProjects] = useState<CarchupaProject[]>([]);
  const { selectedState, selectedPeriod } = settingsStore;

  const [currentPage, setCurrentPage] = useState(1);
  const [projectsPerPage, setProjectsPerPage] = useState(2);
  const [videos, setVideos] = useState<VideoData[]>([]);
  const [rows, setRows] = useState<any>([]);
  const { selectedRowCount, selectedProject } = settingsStore;

  // Logic for displaying current pages
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentPageProjects = projects.slice(indexOfFirstProject, indexOfLastProject);
  settingsStore.updateSelectedRowCount(1);

  const loadProjectsList = useCallback(
    async (selectedState) => {
      settingsStore.updateGlobalLoading(true);
      const projectRequest: any = {
        startDate: (function (startDate) {
          if (!!startDate) {
            const date = new Date(startDate);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            return moment(date).unix();
          } else {
            return undefined;
          }
        })(selectedPeriod?.startDate),
        endDate: (function (endDate) {
          if (!!endDate) {
            const date = new Date(endDate);
            date.setHours(23);
            date.setMinutes(59);
            date.setSeconds(59);
            return moment(date).unix();
          } else {
            return undefined;
          }
        })(selectedPeriod?.endDate),
        limit: 1000,
        offset: 0,
      };

      const response = await API.getProjectList(projectRequest);
      if (response) {
        settingsStore.updateGlobalLoading(false);
        !!selectedState
          ? setProjects(response.data.projects.filter((project: any) => project.countryID === selectedState))
          : setProjects(response.data.projects);

        messageStore.snackbar({
          message: t('Projects loaded'),
          type: 'success',
        });
      } else {
        settingsStore.updateGlobalLoading(false);
        messageStore.snackbar({
          message: t('Failed fetching projects'),
          type: 'error',
        });
      }
    },
    [selectedPeriod]
  );

  const loadVideoList = useCallback(async () => {
    settingsStore.updateGlobalLoading(true);

    const videoRequest: VideoRequest = {
      projectID: undefined,
      startDate: undefined,
      endDate: undefined,
      userIDs: [],
      limit: 1000,
      offset: 0,
    };
    const response = await API.getVideos(videoRequest);
    if (response) {
      settingsStore.updateGlobalLoading(false);
      setVideos(response.data.videos);
      messageStore.snackbar({
        message: t('Videos loaded'),
        type: 'success',
      });
    } else {
      settingsStore.updateGlobalLoading(false);
      messageStore.snackbar({
        message: t('Failed fetching user sessions'),
        type: 'error',
      });
    }
  }, [t]);

  const loadLeaderboard = useCallback(async () => {
    const leaderboardRequest: LeaderboardRequest = {
      projectID: !!selectedProject ? selectedProject : undefined,
      countryID: !!selectedState ? selectedState : undefined,
      startDate: (function (startDate) {
        if (!!startDate) {
          const date = new Date(startDate);
          date.setHours(0);
          date.setMinutes(0);
          date.setSeconds(0);
          return moment(date).unix();
        } else {
          return undefined;
        }
      })(selectedPeriod?.startDate),
      endDate: (function (endDate) {
        if (!!endDate) {
          const date = new Date(endDate);
          date.setHours(23);
          date.setMinutes(59);
          date.setSeconds(59);
          return moment(date).unix();
        } else {
          return undefined;
        }
      })(selectedPeriod?.endDate),
    };

    const response = await API.getLeaderboardPerProjectAndCountry(leaderboardRequest);
    if (response) {
      messageStore.snackbar({
        message: `${t('Leaderboard loaded')}`,
        type: 'success',
      });
      setRows(response.data.leaderboard);
    } else {
      messageStore.snackbar({
        message: `${t('Leaderboard load failed')}`,
        type: 'error',
      });
    }
  }, [t, selectedProject, selectedState, selectedPeriod]);

  const handleClick = (projectID: any) => {
    history.push(ROUTES.createProjectsInfoUrl(projectID));
  };

  const renderCurrentPageProjects = currentPageProjects.map((project: any, index: any) => {
    return (
      <ProjectItemOverview
        key={project.ID}
        projectName={project.name}
        projectDescription={project.description}
        parentStyle={styles.projectItem}
        handleClick={() => handleClick(project.ID)}
        totalCollected={!!project.collectablesCount.collectedCount ? project.collectablesCount.collectedCount : 0}
        totalCount={!!project.collectablesCount.totalCount ? project.collectablesCount.totalCount : 0}
        totalValue={!!project.collectablesCount.totalValue ? project.collectablesCount.totalValue : 0}
        showSelectProjectButton={true}
      >
        <Map projects={project} projectID={project.ID} />
      </ProjectItemOverview>
    );
  });

  // Logic for displaying page numbers

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(projects.length / projectsPerPage); i++) {
    pageNumbers.push(i);
  }
  const pageNumber = pageNumbers.length;

  const handleClickPagination = (event: any) => {
    setCurrentPage(Number(event.target.textContent));
  };

  //
  // PAGINATION
  useEffect(() => {
    loadProjectsList(selectedState);
    loadVideoList();
    loadLeaderboard();

    return function cleanup() {
      settingsStore.updateSelectedRowCount(5);
    };
  }, [loadProjectsList, selectedState]);

  const headCells: Header[] = [
    {
      field: 'ID',
      title: t('ID'),
      sortable: true,
    },
    {
      field: 'firstName',
      title: t('firstName'),
      sortable: true,
    },
    {
      field: 'lastName',
      title: t('lastName'),
      sortable: true,
    },
    {
      field: 'username',
      title: t('username'),
      sortable: true,
    },
    {
      field: 'collectablesInfo.coinsCount',
      title: 'CO',
      sortable: true,
    },
    {
      field: 'collectablesInfo.blueberriesCount',
      title: 'BB',
      sortable: true,
    },
    {
      field: 'collectablesInfo.cherriesCount',
      title: 'CR',
      sortable: true,
    },
    {
      field: 'collectablesInfo.lemonsCount',
      title: 'LE',
      sortable: true,
    },
    {
      field: 'collectablesInfo.strawberriesCount',
      title: 'SB',
      sortable: true,
    },
    {
      field: 'collectablesInfo.diamondsCount',
      title: 'DI',
      sortable: true,
    },
    {
      field: 'collectablesInfo.totalCollectablesCount',
      title: t('Total Collected'),
      sortable: true,
    },
    {
      field: 'totalValue',
      title: t('Total Value'),
      sortable: true,
    },
  ];

  return (
    <SimpleLayout passedStyles={styles.root}>
      <CarchupaPaper childStyle={styles.parent}>
        <h2>Last uploaded videos</h2>
        <VideoList videoList={videos} pageSizeOptions={[1, 5, 10]} {...props} />
        <h2>Projects overview</h2>
        <div className={styles.renderedItems}>{renderCurrentPageProjects}</div>
        <Pagination className={styles.pagination} count={pageNumber} hidePrevButton hideNextButton onClick={handleClickPagination} shape="rounded" />
      </CarchupaPaper>

      <CarchupaPaper type="row" parentStyle={styles.paper}>
        <StateSelect allStatePlaceholder={'All states'} />
        <DateRangeSelect />
      </CarchupaPaper>

      <MaterialTable
        title={t('Leaderboard')}
        columns={headCells}
        data={rows}
        options={{
          search: false,
          pageSize: selectedRowCount,
        }}
        components={{
          Container: (props) => <CarchupaPaper {...props} type="column" parentStyle={styles.paper} childStyle={styles.table} />,
        }}
        onChangeRowsPerPage={(rows) => {
          settingsStore.updateSelectedRowCount(rows);
        }}
      />
    </SimpleLayout>
  );
};

export default inject()(observer(Overview));
