import React from 'react';
import Authorization from './components/UX/system/Authorization';
import AppProvider from './AppProvider';
import authStore from './stores/authStore';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import AppRouter from './components/UX/system/Router';

function App() {
  return (
    <AppProvider>
      <Authorization authStore={authStore}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AppRouter />
        </MuiPickersUtilsProvider>
      </Authorization>
    </AppProvider>
  );
}

export default App;
