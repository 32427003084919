import React, { Fragment, useState } from 'react';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

interface YearMonthPickerProps {
  parentCallback: any;
  limit: Date;
}

function YearMonthPicker(props: YearMonthPickerProps) {
  const { parentCallback, limit } = props;
  const [selectedDate, handleDateChange] = useState(moment(limit));  

  const handleChange = (date: any) => {
    handleDateChange(date);
    parentCallback(date);
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Fragment>
        <DatePicker
          openTo="year"
          views={['year', 'month']}
          label="Year and Month"
          value={selectedDate}
          maxDate={limit}
          onChange={handleChange}
        />
      </Fragment>
    </MuiPickersUtilsProvider>
  );
}

export default YearMonthPicker;
