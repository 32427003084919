import { Grid, Paper, Typography } from "@material-ui/core";
import React, { PropsWithChildren } from "react";
import styles from "./CarchupaPaper.module.scss";

interface CarchupaPaperProps {
  title?: string;
  parentStyle?: string;
  childStyle?: string;
  type?: "row" | "row-reverse" | "column" | "column-reverse" | undefined;
  justify?: "center" | "flex-start" | "flex-end" | "space-between" | "space-around" | "space-evenly" | undefined;
  alignItems?: "center" | "flex-start" | "flex-end" | "stretch" | "baseline" | undefined
}

const CarchupaPaper = (props: PropsWithChildren<CarchupaPaperProps>) => {
  const { children, title, childStyle, parentStyle, type, justify, alignItems } = props;
  return (
    <Paper className={`${styles.paper} ${parentStyle}`}>
      {!!title ? (
        <Typography variant="h6">
          {title}
        </Typography>
      ) : null}

      {!!children ? (
        <Grid
          container
          direction={!!type ? type : "row"}
          justify={!!justify ? justify : undefined }
          alignItems={!!alignItems ? alignItems : undefined  }
          className={`${styles.content} ${childStyle}`}
          spacing={2}
        >
          {children}
        </Grid>
      ) : null}
    </Paper>
  );
};

export default CarchupaPaper;
