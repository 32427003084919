import { Button, Checkbox, Chip, Grid, Paper, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import VideoPlayer from '../../UX/player/Player';
import styles from './VideoItem.module.scss';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { inject, observer } from 'mobx-react';
import videoStore from '../../../stores/videoStore';
import { ROUTES } from '../../../parameters/routes';
import { VideoData } from '../../../models/video';
import { RouteComponentProps } from 'react-router-dom';
import { getVideoStatus } from '../../../logic/get-video-status';

interface VideoItemProps extends RouteComponentProps {
  title?: string;
  parentStyle?: string;
  childStyle?: string;
  video: VideoData;
}

const VideoItem = (props: VideoItemProps) => {
  const { title, childStyle, parentStyle, video, history } = props;
  const { selectedVideos } = videoStore;

  // console.log("selectedVideos212", JSON.stringify(selectedVideos));

  const handleChange = (event: { target: { checked: React.SetStateAction<boolean> } }) => {
    /// console.log("event.target.checked", event.target.checked);
    if (event.target.checked) {
      videoStore.addSelectedVideo(video.ID);
    } else {
      videoStore.removeSelectedVideo(video.ID);
    }
  };

  const selectVideo = (videoId: number) => {
    history.push(ROUTES.createVideoUrl(videoId));
  };

  const videoStatus = useMemo(() => getVideoStatus(video), [video]);

  return (
    <Paper className={`${styles.paper} ${parentStyle} ${selectedVideos.includes(video.ID) ? styles.selectedVideo : ''}`}>
      {!!title ? <Typography variant="h6">{title}</Typography> : null}
      <Grid container direction={'column'} justify={undefined} alignItems={undefined} className={`${styles.content} ${childStyle}`} spacing={2}>
        <div className={styles.videoPlayer}>{video.URL ? <VideoPlayer videoSrc={video.URL} /> : null}</div>
        <div className={styles.videoInfo}>
          <div className={styles.videoInfoRow}>
            <span>VideoID: {video.ID}</span>
            <span>|</span>
            <span>{video.createdAt}</span>
          </div>
          <div className={styles.videoInfoRow}>
            <span>{video.name}</span>
          </div>
        </div>
        <div className={styles.videoActions}>
          <Checkbox
            checked={selectedVideos.includes(video.ID)}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'primary checkbox' }}
            color="primary"
          />

          <Chip label={videoStatus} />

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              selectVideo(video.ID);
            }}
          >
            Select <ArrowRightIcon />
          </Button>
        </div>
      </Grid>
    </Paper>
  );
};

export default inject('videoStore', 'messageStore')(observer(VideoItem));
