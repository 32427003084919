import React, { useCallback, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { inject, observer } from 'mobx-react';
import { Button} from '@material-ui/core';
import { DrivingSession } from '../../../models/sessions';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useRouteMatch, RouteComponentProps } from 'react-router-dom';
import { API } from '../../../api';
import sessionsStore from '../../../stores/sessionsStore';
import { ROUTES } from '../../../parameters/routes';
import MaterialTable from 'material-table';
import { Header } from '../../../models/tables';
import styles from './Sessions.module.scss';
import messageStore from '../../../stores/messageStore';
import { useTranslation } from 'react-i18next';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import settingsStore from '../../../stores/settingsStore';

interface SessionsProps extends RouteComponentProps {}

const Sessions = (props: SessionsProps) => {
  const { history } = props;
  const { selectedRowCount } = settingsStore;
  const { t } = useTranslation();
  const match = useRouteMatch<{ userId: any }>();
  const [dense, setDense] = React.useState(false);
  const [rows, setRows] = React.useState<DrivingSession[]>([]);


  const headCells: Header[] = [
    {
      field: 'ID',
      title: t('ID'),
      sortable: true,
    },
    {
      field: 'userID',
      title: t('User ID'),
      sortable: true,
    },
    {
      field: 'isActive',
      title: t('Active'),
      sortable: true,
      render: (rowData: DrivingSession) =>
        rowData.isActive ? (
          <CheckCircleOutlineIcon color="primary" />
        ) : (
          <BlockIcon color="error" />
        ),
    },
    {
      field: 'startedAt',
      title: t('Started'),
      sortable: true,
    },
    {
      field: 'videosCount',
      title: t('Total Videos'),
      sortable: true,
    },
    {
      field: 'menu',
      title: '',
      sortable: false,
      render: (rowData: DrivingSession) => (
        <Button onClick={(event) => selectSession(event, rowData)}>
          <ChevronRightIcon />
        </Button>
      ),
    },
  ];

  const loadSessions = useCallback(async () => {
    let response = null;
    if (match.params.userId) {
      response = await API.getUserSessions(match.params.userId);
    } else if (!!sessionsStore.getSessions.length) {
      setRows(sessionsStore.getSessions);
      return;
    } else {
      response = await API.getAllSessions();
    }

    if (response) {
      messageStore.snackbar({ message: t('Sessions loaded'), type: 'success' });
      sessionsStore.updateSessions(response.data.drivingSessions);
      setRows(response.data.drivingSessions);
    } else {
      messageStore.snackbar({ message: t('Failed fetching user sessions'), type: 'error' });
    }
  }, [match.params.userId, t]);

  useEffect(() => {
    loadSessions();
  }, [loadSessions]);

  const selectSession = (
    event: React.MouseEvent<unknown>,
    session: DrivingSession
  ) => {
    messageStore.snackbar({message: `${t('Session loaded')}: ${session.ID}`, type: 'info'});
    sessionsStore.updateSession(session);
    history.push(ROUTES.createVideosUrl(session.ID));
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };


  return (
    <SimpleLayout passedStyles={styles.root}>
      <Paper className={styles.paper}>
        <MaterialTable
          title={t('Sessions')}
          columns={headCells}
          data={rows}
          options={{
            search: true,
            padding: dense ? 'dense' : 'default',
            pageSize: selectedRowCount,
          }}
          onChangeRowsPerPage={(rows) => {
            settingsStore.updateSelectedRowCount(rows);
          }}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label={t('Dense padding')}
      />
    </SimpleLayout>
  );
};

export default inject('sessionsStore', 'messageStore', 'settingsStore')(observer(Sessions));
