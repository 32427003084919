import React, { useEffect, useState, useCallback } from 'react';
import { RouteComponentProps, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import MaterialTable from 'material-table';

import { Header } from '../../../models/tables';

import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import settingsStore from '../../../stores/settingsStore';
import messageStore from '../../../stores/messageStore';
import { LeaderboardRequest, LeaderboardWithFiltering } from '../../../models/general';
import { VideoData, VideoRequest } from '../../../models/video';
import { API } from '../../../api';
import { ROUTES } from '../../../parameters/routes';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import CarchupaPaper from '../../../components/UI/papper/CarchupaPaper';
import ProjectItem from '../../../components/UI/projects-item/ProjectsItem';
import Map from '../../../components/UX/map/Map';
import VideoList from '../../../components/UX/video-list/VideoList';
import videoStore from '../../../stores/videoStore';
import CarchupaDialog from '../../../components/UX/dialog/CarchupaDialog';

import styles from './Project.module.scss';

interface Props extends RouteComponentProps {}

const Project = (props: Props) => {
  const { history } = props;
  const [loading, setloading] = useState(true);
  const [project, setProject] = useState<any>([]);
  const [collectablesCount, setCollectablesCount] = useState<any>({});
  const [availableCollectablesList, setAvailableCollectablesList] = useState<any>([]);
  const [collectedCollectablesList, setCollectedCollectablesList] = useState<any>([]);
  const [videos, setVideos] = useState<VideoData[]>([]);
  const [rows, setRows] = useState<LeaderboardWithFiltering[]>([]);
  // const [leaderboard, setleaderboard] = useState<CarchupaProjectLeaderboard>();
  const { selectedRowCount } = settingsStore;

  const [dialogDelete, setDialogDelete] = useState(false);
  const { t } = useTranslation();
  const match = useRouteMatch<{ projectId: string }>();
  const currentProjectId = Number(match.params.projectId);
  settingsStore.updateSelectedRowCount(1);

  const loadProject = useCallback(async () => {
    setloading(true);
    settingsStore.updateGlobalLoading(true);

    const response = await API.getProject(currentProjectId);
    if (response) {
      setloading(false);
      settingsStore.updateGlobalLoading(false);
      setProject(response.data.project);

      setAvailableCollectablesList(response.data.availableCollectablesList);
      setCollectedCollectablesList(response.data.collectedCollectablesList);
      setCollectablesCount(response.data.collectablesCount);
      messageStore.snackbar({
        message: t('Projects loaded'),
        type: 'success',
      });
    } else {
      setloading(false);
      settingsStore.updateGlobalLoading(false);
      messageStore.snackbar({
        message: t('Failed fetching projects'),
        type: 'error',
      });
    }
  }, []);

  const loadLeaderboard = useCallback(async () => {
    const leaderboardRequest: LeaderboardRequest = {
      projectID: !!currentProjectId ? currentProjectId : undefined,
      countryID: undefined,
      startDate: undefined,
      endDate: undefined,
    };

    const response = await API.getLeaderboardPerProjectAndCountry(leaderboardRequest);
    if (response) {
      messageStore.snackbar({
        message: `${t('Leaderboard loaded')}`,
        type: 'success',
      });
      setRows(response.data.leaderboard);
    } else {
      messageStore.snackbar({
        message: `${t('Leaderboard load failed')}`,
        type: 'error',
      });
    }
  }, []);

  const loadVideoList = useCallback(async () => {
    setloading(true);
    settingsStore.updateGlobalLoading(true);

    const videoRequest: VideoRequest = {
      projectID: !!currentProjectId ? currentProjectId : undefined,
      startDate: undefined,
      endDate: undefined,
      userIDs: [],
      limit: 1000,
      offset: 0,
    };
    const response = await API.getVideos(videoRequest);
    if (response) {
      setloading(false);
      settingsStore.updateGlobalLoading(false);
      setVideos(response.data.videos);
      messageStore.snackbar({
        message: t('Videos loaded'),
        type: 'success',
      });
    } else {
      setloading(false);
      settingsStore.updateGlobalLoading(false);
      messageStore.snackbar({
        message: t('Failed fetching user sessions'),
        type: 'error',
      });
    }
  }, []);

  const handleButtonDelete = () => {
    setDialogDelete(true);
  };

  const handleButtonUpload = () => {
    history.push(ROUTES.coinUpload);
  };

  const onCloseDialogDelete = useCallback(async (status: boolean) => {
    setDialogDelete(false);
    if (status) {
      const response = await API.deleteProject(currentProjectId);

      if (response) {
        history.push(ROUTES.projects);
      } else {
        messageStore.snackbar({
          message: t('Failed deleting project'),
          type: 'error',
        });
      }
    }
  }, []);

  useEffect(() => {
    loadProject();
    loadVideoList();
    loadLeaderboard();
    videoStore.updateVideos([]);

    return function cleanup() {
      settingsStore.updateSelectedRowCount(5);
    };
  }, []);

  const headCells: Header[] = [
    {
      field: 'ID',
      title: t('ID'),
      sortable: true,
    },
    {
      field: 'firstName',
      title: t('firstName'),
      sortable: true,
    },
    {
      field: 'lastName',
      title: t('lastName'),
      sortable: true,
    },
    {
      field: 'username',
      title: t('username'),
      sortable: true,
    },
    {
      field: 'collectablesInfo.coinsCount',
      title: 'CO',
      sortable: true,
    },
    {
      field: 'collectablesInfo.blueberriesCount',
      title: 'BB',
      sortable: true,
    },
    {
      field: 'collectablesInfo.cherriesCount',
      title: 'CR',
      sortable: true,
    },
    {
      field: 'collectablesInfo.lemonsCount',
      title: 'LE',
      sortable: true,
    },
    {
      field: 'collectablesInfo.strawberriesCount',
      title: 'SB',
      sortable: true,
    },
    {
      field: 'collectablesInfo.diamondsCount',
      title: 'DI',
      sortable: true,
    },
    {
      field: 'collectablesInfo.totalCollectablesCount',
      title: t('Total Collected'),
      sortable: true,
    },
    {
      field: 'totalValue',
      title: t('Total Value'),
      sortable: true,
    },
  ];

  return (
    <>
      <SimpleLayout passedStyles={styles.root}>
        <CarchupaPaper>
          <div className={styles.buttons}>
            <Button startIcon={<DeleteIcon />} variant="contained" color="primary" type="submit" onClick={handleButtonDelete}>
              {t('Delete uncollected collectibles')}
            </Button>
            <Button startIcon={<CloudUploadIcon />} variant="contained" color="primary" type="submit" onClick={handleButtonUpload}>
              Add Collectibles
            </Button>
          </div>
          <ProjectItem
            key={project.ID}
            projectName={project.name}
            projectDescription={project.description}
            parentStyle={styles.parent}
            collectable={collectablesCount}
            totalCollected={collectedCollectablesList.length}
            totalValue={!!project.currentProjectId ? project.currentProjectId.collectablesCount.totalValue : 0}
          >
            <Map projects={project} projectID={currentProjectId}></Map>
          </ProjectItem>
        </CarchupaPaper>
        <VideoList videoList={videos} pageSizeOptions={[1, 5, 10]} {...props} />

        <MaterialTable
          title={t('Leaderboard')}
          columns={headCells}
          data={rows}
          options={{
            search: false,
            pageSize: selectedRowCount,
          }}
          components={{
            Container: (props) => <CarchupaPaper {...props} type="column" parentStyle={styles.paper} childStyle={styles.table} />,
          }}
          onChangeRowsPerPage={(rows) => {
            settingsStore.updateSelectedRowCount(rows);
          }}
        />

        <CarchupaDialog
          open={dialogDelete}
          handleClose={onCloseDialogDelete}
          message={t('Do you want to delete selected project?')}
          title={t('Confirm Delete')}
        />
      </SimpleLayout>
    </>
  );
};

export default Project;
