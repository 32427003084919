import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import styles from './ProjectsItem.module.scss';

interface ProjectItemProps {
  projectName: string;
  projectDescription: string;
  handleClick?: () => void;
  parentStyle?: string;
  children?: any;
  collectable: any;
  totalCollected?: number;
  totalValue?: number;
  showSelectProjectButton?: boolean;
}

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#3f51b5',
    },
  })
)(LinearProgress);

const ProjectItem = (props: ProjectItemProps) => {
  const {
    projectName,
    projectDescription,
    handleClick,
    parentStyle,
    children,
    collectable,
    totalCollected,
    totalValue,
    showSelectProjectButton,
  } = props;

  console.log('collectable', collectable);
  
  const {
    collectedCoins,
    totalCoins,
    collectedBlueberries,
    totalBlueberries,
    collectedCherries,
    totalCherries,
    collectedLemons,
    totalLemons,
    collectedStrawberries,
    totalStrawberries,
    collectedDiamonds,
    totalDiamonds,
  } = collectable;

  return (
    <div className={`${styles.container} ${parentStyle}`}>
      <div>
        <div className={styles.collectablesHeader}>
          <div>Total collected: {totalCollected}</div>
          <div>Total value: {totalValue}</div>
        </div>
        <div>{children}</div>

        <div className={styles.header}>
          <div>
            <div className={styles.title} onClick={handleClick}>
              {projectName}
            </div>
            <div className={styles.projectDescription}>{projectDescription}</div>
          </div>
        </div>
      </div>

      <div className={styles.collectables}>
        <div>
          Coins: {collectedCoins}/{totalCoins}
          <BorderLinearProgress variant="determinate" value={totalCoins ? (collectedCoins / totalCoins) * 100 : 0} />
        </div>
        <div>
          Blueberries: {collectedBlueberries}/{totalBlueberries}
          <BorderLinearProgress variant="determinate" value={totalBlueberries ? (collectedBlueberries / totalBlueberries) * 100 : 0} />
        </div>
        <div>
          Cherries: {collectedCherries}/{totalCherries}
          <BorderLinearProgress variant="determinate" value={totalCherries ? (collectedCherries / totalCherries) * 100 : 0} />
        </div>
        <div>
          Lemons: {collectedLemons}/{totalLemons}
          <BorderLinearProgress variant="determinate" value={totalLemons ? (collectedLemons / totalLemons) * 100 : 0} />
        </div>
        <div>
          Strawberries: {collectedStrawberries}/{totalStrawberries}
          <BorderLinearProgress variant="determinate" value={totalStrawberries ? (collectedStrawberries / totalStrawberries) * 100 : 0} />
        </div>
        <div>
          Diamonds: {collectedDiamonds}/{totalDiamonds}
          <BorderLinearProgress variant="determinate" value={totalDiamonds ? (collectedDiamonds / totalDiamonds) * 100 : 0} />
        </div>
        {showSelectProjectButton && (
          <Button className={styles.button} variant="contained" color="primary" type="submit" onClick={handleClick}>
            Select Project
          </Button>
        )}
      </div>
    </div>
  );
};

export default ProjectItem;
