import React, { useCallback, useEffect } from 'react';
import styles from './Payments.module.scss';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { inject, observer } from 'mobx-react';
import { PaymentUser, User } from '../../../models/general';
import { Button, LinearProgress } from '@material-ui/core';
import { Header } from '../../../models/tables';
import { API } from '../../../api';
import { RouteComponentProps } from 'react-router-dom';
import moment, { Moment } from 'moment';
import MaterialTable from 'material-table';
import messageStore from '../../../stores/messageStore';
import { useTranslation } from 'react-i18next';
import YearMonthPicker from '../../../components/UX/system/YearMonthPicker';
import CarchupaPaper from '../../../components/UI/papper/CarchupaPaper';
import ProjectSelect from '../../../components/UX/project-select/ProjectSelect';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import { ROUTES } from '../../../parameters/routes';
import settingsStore from '../../../stores/settingsStore';

interface PaymentsProps extends RouteComponentProps {}

const Payments = (props: PaymentsProps) => {
  const { history } = props;
  const { selectedRowCount, selectedProject } = settingsStore;
  const { t } = useTranslation();
  const datelimit = new Date();
  datelimit.setDate(31);
  const [rows, setRows] = React.useState<PaymentUser[]>([]);
  const [selected, setSelected] = React.useState<number[]>([]);
  const [dense, setDense] = React.useState(false);
  const [selectedStartDate, handleStartDateChange] = React.useState(moment(datelimit));
  const [selectedEndDate, handleEndDateChange] = React.useState(moment(datelimit));
  const [loading, setloading] = React.useState(true);

  const headCells: Header[] = [
    {
      field: 'ID',
      title: t('ID'),
      sortable: true,
    },
    {
      field: 'username',
      title: t('username'),
      sortable: true,
    },
    {
      field: 'IBAN',
      title: t('IBAN'),
      sortable: false,
    },
    {
      field: 'taxPercentage',
      title: t('Tax'),
      sortable: false,
    },
    {
      field: 'socialSecurityNumber',
      title: t('SSN'),
      sortable: false,
    },
    {
      field: 'totalEarnings.coinCount',
      title: t('Coins'),
      sortable: true,
    },
    {
      field: 'totalEarnings.blueberryCount',
      title: t('Bluberries'),
      sortable: true,
    },
    {
      field: 'totalEarnings.cherryCount',
      title: t('Cherries'),
      sortable: true,
    },
    {
      field: 'totalEarnings.lemonCount',
      title: t('Lemons'),
      sortable: true,
    },
    {
      field: 'totalEarnings.strawberryCount',
      title: t('Strawberries'),
      sortable: true,
    },
    {
      field: 'totalEarnings.diamondCount',
      title: t('Diamonds'),
      sortable: true,
    },
    {
      field: 'totalCollectablesCount',
      title: t('Total Collected'),
      sortable: true,
    },
    {
      field: 'rawTotalValue',
      title: t('Total Value'),
      sortable: true,
      render: (rowData: PaymentUser) => <span>{Math.round(rowData.rawTotalValue * 100) / 100}</span>,
    },
    {
      field: 'status',
      title: t('Status'),
      sortable: true,
      render: (rowData: PaymentUser) => (rowData.isPaid === 'pending' ? t('Pending') : t('Paid')),
    },
    {
      field: 'userPayments',
      title: t('User Payments'),
      sortable: false,
      render: (rowData: any) => (
        <Button onClick={() => selectUserPayments(rowData)}>
          <MonetizationOnIcon />
        </Button>
      ),
    },
  ];

  const loadUserPayments = useCallback(async () => {
    setRows([]);
    setloading(true);
    const response = await API.getUserPayments(moment(selectedStartDate).startOf('month').unix(), selectedEndDate.unix(), selectedProject);

    if (response) {
      setloading(false);
      messageStore.snackbar({
        message: `${t('User list for')} ${moment(selectedStartDate).format('MMM YYYY')} ${t('loaded')}`,
        type: 'success',
      });
      setRows(response.data);
    } else {
      setloading(false);
      messageStore.snackbar({
        message: `${t('User list for')} ${moment(selectedStartDate).format('MMM YYYY')} ${t('load failed')}`,
        type: 'error',
      });
    }
  }, [selectedEndDate, selectedProject, selectedStartDate, t]);

  useEffect(() => {
    loadUserPayments();
  }, [loadUserPayments]);

  const saveUserPayments = useCallback(async () => {
    const response = await API.setUserPayments(selected, moment(selectedStartDate).startOf('month').unix(), selectedEndDate.unix());
    setSelected([]);
    if (response) {
      messageStore.snackbar({
        message: t('Payments updated'),
        type: 'success',
      });
      loadUserPayments();
    } else {
      messageStore.snackbar({
        message: t('Update payments failed'),
        type: 'error',
      });
    }
  }, [selected, selectedEndDate, t, selectedStartDate, loadUserPayments]);

  const selectUserPayments = (user: User) => {
    history.push({
      pathname: ROUTES.createUsersPaymentsUrl(user.ID),
      state: {
        startDate: moment(selectedStartDate).startOf('month').unix(),
        endDate: moment(selectedEndDate).unix(),
      },
    });
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const selectStartDateCallbackFunction = (date: Moment) => {
    handleStartDateChange(date);
  };

  const selectEndDateCallbackFunction = (date: Moment) => {
    handleEndDateChange(date);
  };

  return (
    <SimpleLayout passedStyles={styles.root}>
      <CarchupaPaper type="row" parentStyle={styles.paper}>
        <ProjectSelect></ProjectSelect>
        <YearMonthPicker parentCallback={selectStartDateCallbackFunction} limit={selectedEndDate.toDate() as Date} />
        <YearMonthPicker parentCallback={selectEndDateCallbackFunction} limit={datelimit} />
      </CarchupaPaper>
      {selectedStartDate && rows.length > 0 ? (
        <>
          <MaterialTable
            title={t('User Payments')}
            columns={headCells}
            data={rows}
            options={{
              search: true,
              selection: true,
              exportButton: true,
              padding: dense ? 'dense' : 'default',
              showSelectAllCheckbox: false,
              selectionProps: (rowData: PaymentUser) => ({
                disabled: rowData.isPaid === 'paid',
                color: 'primary',
              }),
              pageSize: selectedRowCount,
            }}
            actions={[
              {
                tooltip: t('Mark as paid'),
                icon: 'attach_money',
                onClick: (evt, data: any) => saveUserPayments(),
              },
            ]}
            components={{
              Container: (props) => <CarchupaPaper {...props} type="column" parentStyle={styles.paper} childStyle={styles.table} />,
            }}
            onSelectionChange={(rows) => {
              setSelected(rows.map((el) => el.ID));
            }}
            onChangeRowsPerPage={(rows) => {
              settingsStore.updateSelectedRowCount(rows);
            }}
          />

          <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label={t('Dense padding')} />
        </>
      ) : loading ? (
        <LinearProgress />
      ) : (
        <CarchupaPaper type="row" parentStyle={styles.paper}>
          <div>No users payments</div>
        </CarchupaPaper>
      )}
    </SimpleLayout>
  );
};

export default inject('userStore', 'messageStore', 'settingsStore')(observer(Payments));
