import { FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import videoStore from '../../../stores/videoStore';
import styles from './VideoTypeSelect.module.scss';

interface Props {}

const VideoTypeSelect = (props: Props) => {
  const { selectedVideoType } = videoStore;
  const [rows, setRows] = useState<{ name: string; value: string }[]>([]);

  const handleChange = (event: { target: { value: any } }) => {
    videoStore.updateSelectedVideoType(event.target.value);
  };

  useEffect(() => {
    setRows([
      {
        name: 'Uploaded',
        value: 'isUploaded',
      },
      {
        name: 'Finished',
        value: 'isFinished',
      },
      {
        name: 'Approved',
        value: 'isApproved',
      },
      {
        name: 'Rejected',
        value: 'isRejected',
      },
      {
        name: 'Uploading to Vaisala',
        value: 'isVaisalaStart',
      },
      {
        name: 'Uploaded to Vaisala',
        value: 'isVaisalaEnd',
      },
      {
        name: 'Error upload to Vaisala',
        value: 'isVaisalaError',
      },
    ]);
  }, []);

  return (
    <FormControl className={styles.formControl}>
      <InputLabel shrink id="project-select-label">
        Video Status
      </InputLabel>
      <Select
        labelId="project-select-label"
        id="project-select"
        value={!!selectedVideoType ? selectedVideoType : ''}
        onChange={handleChange}
        displayEmpty
      >
        <MenuItem value="">
          <em>All</em>
        </MenuItem>
        {rows.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default inject('videoStore', 'videoStore')(observer(VideoTypeSelect));
