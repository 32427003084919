import { FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { API } from "../../../api";
import { CarchupaProject } from "../../../models/general";
import messageStore from "../../../stores/messageStore";
import settingsStore from "../../../stores/settingsStore";
import styles from "./ProjectSelect.module.scss";

interface Props {}

const ProjectSelect = (props: Props) => {
  const { selectedProject, selectedState } = settingsStore;
  const { t } = useTranslation();
  const [rows, setRows] = useState<CarchupaProject[]>([]);

  const handleChange = (event: { target: { value: any } }) => {
    settingsStore.updateSelectedProject(event.target.value);
  };

  const loadProjectList = useCallback(async () => {
    const response = await API.getProjectList();
    if (response) {
      setRows(
        response.data.projects.filter((project) => {
          return !!selectedState ? project.countryID === selectedState : true;
        })
      );
      // messageStore.snackbar({message: `${t('Projects loaded')}`, type: 'info'});
    } else {
      messageStore.snackbar({
        message: `${t("Failed to fetch project list")}`,
        type: "error",
      });
    }
  }, [selectedState, t]);

  useEffect(() => {
    console.log('ProjectSelect loaded');
    loadProjectList();
  }, [loadProjectList]);

  return (
    <FormControl className={styles.formControl}>
      <InputLabel shrink id="project-select-label">
        Project
      </InputLabel>
      <Select
        labelId="project-select-label"
        id="project-select"
        value={!!selectedProject ? selectedProject : ""}
        onChange={handleChange}
        displayEmpty
      >
        <MenuItem value="">
          <em>All</em>
        </MenuItem>
        {rows.map((item: CarchupaProject) => (
          <MenuItem key={item.ID} value={item.ID}>
            {item.name} ({item.ID})
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default inject("settingsStore", "messageStore")(observer(ProjectSelect));
