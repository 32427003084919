import { VideoData } from '../models/video';

export function getVideoStatus(video: VideoData | undefined) {
  if (!!video) {
    let status = 'created';
    if (!!video.isFinished) {
      status = 'finished';
    }
    if (!video.isApproved && video.isOnVaisala === 0) {
      status = 'pending';
    }
    if (video.isApproved === 1 && video.isOnVaisala === 0) {
      status = 'approved';
    }
    if (video.isApproved === 0 && video.isOnVaisala === 0) {
      status = 'rejected';
    }
    if (video.isOnVaisala === 1) {
      status = 'uploading';
    }
    if (video.isOnVaisala === 2) {
      status = 'inVaisala';
    }
    if (video.isOnVaisala === 3) {
      status = 'errored';
    }
    return status;
  } else {
    return '';
  }
}
