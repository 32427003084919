import { observable, action, decorate, computed } from 'mobx';
import { AuthToken, LoginOptions, AdminRole } from '../models/general';

const TOKEN_STORAGE_KEY = 'authorization/token';
const ADMIN_ROLE_STORAGE_KEY = 'authorization/adminRole';

export class AuthStore {
  token?: AuthToken = window.localStorage.getItem(TOKEN_STORAGE_KEY) ?? window.sessionStorage.getItem(TOKEN_STORAGE_KEY) ?? undefined;
  adminRole?: AdminRole = window.localStorage.getItem(ADMIN_ROLE_STORAGE_KEY) ?? window.sessionStorage.getItem(ADMIN_ROLE_STORAGE_KEY) ?? undefined;

  setToken(token: AuthToken, options: LoginOptions) {
    this.clearToken();
    if (options.rememberMe) {
      window.localStorage.setItem(TOKEN_STORAGE_KEY, token);
    } else {
      window.sessionStorage.setItem(TOKEN_STORAGE_KEY, token);
    }
    this.token = token;
  }
  clearToken() {
    window.localStorage.removeItem(TOKEN_STORAGE_KEY);
    window.sessionStorage.removeItem(TOKEN_STORAGE_KEY);
    this.token = undefined;
  }
  setAdminRole(adminRole: AdminRole, options: LoginOptions) {
    this.clearAdminRole();
    if (options.rememberMe) {
      window.localStorage.setItem(ADMIN_ROLE_STORAGE_KEY, adminRole);
    } else {
      window.sessionStorage.setItem(ADMIN_ROLE_STORAGE_KEY, adminRole);
    }
    this.adminRole = adminRole;
  }
  clearAdminRole() {
    this.adminRole = undefined;
  }

  logout() {
    this.clearToken();
    this.clearAdminRole();
    window.location.reload();
  }

  get isAuthorized(): boolean {
    return !!this.token;
  }
}

decorate(AuthStore, {
  token: observable,
  adminRole: observable,

  setToken: action,
  clearToken: action,
  setAdminRole: action,
  clearAdminRole: action,
  logout: action,

  isAuthorized: computed,
});

export default new AuthStore();
