import React, { useCallback, useEffect } from 'react';
import styles from './Notifications.module.scss';
import CarchupaPaper from '../../../components/UI/papper/CarchupaPaper';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import { Formik, Form, Field, useField } from 'formik';
import ProjectSelect from '../../../components/UX/project-select/ProjectSelect';
import * as Yup from 'yup';
import { Button } from '@material-ui/core';
import StateSelect from '../../../components/UX/state-select/StateSelect';
import { useCallbackWithLoading } from '../../../hooks/use-callback-with-loading';
import { API } from '../../../api';
import { NotificationBroadcast } from '../../../models/general';
import { inject, observer } from 'mobx-react';
import settingsStore from '../../../stores/settingsStore';
import userStore from '../../../stores/userStore';
import messageStore from '../../../stores/messageStore';
import { useTranslation } from 'react-i18next';
import UsersSelect from '../../../components/UX/users-select/UsersSelect';

interface Props {}

const CustomTextArea = ({ ...props }: any) => {
  const [field, meta] = useField(props);
  return (
    <>
      <textarea className={styles.textArea} {...field} {...props} />
      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
    </>
  );
};

const Notifications = (props: Props) => {
  const { t } = useTranslation();
  const { selectedUsers } = userStore;
  const { selectedProject, selectedState } = settingsStore;

  const [send, sendPending] = useCallbackWithLoading(API.sendNotifications);
  const load = useCallback(async () => {}, []);

  const NotificationSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    message: Yup.string().required('Required'),
  });

  const sendNotification = useCallback(
    async (values: { title: any; message: any }, setSubmitting: (isSubmitting: boolean) => void, resetForm) => {
      //console.log('sendNotification');
      const { title, message } = values;
      const data: NotificationBroadcast = {
        title: title,
        message: message,
      };
      if (!!selectedUsers.length) {
        data.userIDs = selectedUsers.map((user) => user.ID);
      } else if (!!selectedProject) {
        data.projectID = selectedProject;
      } else if (!!selectedState) {
        data.countryID = selectedState;
      }
      //console.log(data);
      // TODO reqest
      if (!!selectedUsers.length || !!selectedProject || !!selectedState) {
        await send(data)
          .then((res) => {
            messageStore.snackbar({
              message: `${t('Message sent')}`,
              type: 'info',
            });
            //console.log("res", res);
            resetForm({});
          })
          .catch((err) =>
            messageStore.snackbar({
              message: `${t('Failed to send notification')}`,
              type: 'error',
            })
          );
      } else {
        messageStore.snackbar({
          message: `${t('Please select user')}`,
          type: 'error',
        });
      }
      setSubmitting(false);
    },
    [selectedProject, selectedState, selectedUsers, send, t]
  );

  useEffect(() => {
    load();
  }, [load]);

  return (
    <SimpleLayout passedStyles={styles.root}>
      <CarchupaPaper type="row" parentStyle={styles.paper} childStyle={styles.generalInfo}>
        <StateSelect allStatePlaceholder={'All states'} />
        <ProjectSelect />
        <UsersSelect usersDropdownTitle={'All users'} />
      </CarchupaPaper>
      <CarchupaPaper type="column" parentStyle={`${styles.paper} ${styles.messagebox}`} childStyle={styles.generalInfo}>
        <div>
          <Formik
            initialValues={{
              title: '',
              message: '',
            }}
            onSubmit={(values: any, { setSubmitting, resetForm }: any) => {
              setSubmitting(true);
              sendNotification(values, setSubmitting, resetForm);
            }}
            validationSchema={NotificationSchema}
          >
            {({ errors, touched, isSubmitting, setFieldValue }: any) => (
              <Form className={styles.notificationForm}>
                <Field type="textarea" name="title" placeholder="Title" disabled={isSubmitting} />
                {errors.title && touched.title ? <div>{errors.title}</div> : null}

                <CustomTextArea name="message" rows="6" placeholder="Message" disabled={isSubmitting} />

                {/* <Field
                  type="textarea"
                  name="message"
                  placeholder="Message"
                  disabled={isSubmitting}
                />
                 {errors.message && touched.message ? (
                  <div>{errors.message}</div>
                ) : null} */}
                <div className={styles.sendButton}>
                  <Button variant="contained" color="primary" type="submit" disabled={isSubmitting || sendPending} className={styles.submitButton}>
                    Send
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </CarchupaPaper>
    </SimpleLayout>
  );
};

export default inject('settingsStore', 'userStore', 'messageStore')(observer(Notifications));
