
import { observable, computed, action, decorate } from 'mobx';
import { DrivingSession } from '../models/sessions';
import { API } from '../api';
import userStore from './userStore';

export class SessionsStore {
    readonly sessions = observable<DrivingSession>([]);
    selectedSession: DrivingSession | undefined;

    updateSession(newSession: DrivingSession | undefined) {
        this.selectedSession = newSession;
    }


    updateSessions(newSessions: DrivingSession[]) {
        this.sessions.replace(newSessions);
    }

     async refreshSessions(callback: any) {
        const user = userStore.selectedUser;
        const call = user ? API.getUserSessions(user.ID) : API.getAllSessions();
        const response = await call;
        if (response) {
            this.updateSessions(response.data.drivingSessions);
            callback();
        }
    }
    

    get getSessions(): DrivingSession[] {
        return this.sessions;
    }

}


decorate(SessionsStore, {
    sessions: observable,
    selectedSession: observable,

    updateSessions: action,
    refreshSessions: action,

    getSessions: computed,
});


export default new SessionsStore();
