import React, { useCallback, useEffect, useState } from 'react';
import styles from './Leaderboard.module.scss';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { inject, observer } from 'mobx-react';
import { LeaderboardWithFiltering, LeaderboardRequest } from '../../../models/general';
import { Header } from '../../../models/tables';
import { API } from '../../../api';
import { RouteComponentProps } from 'react-router-dom';
import MaterialTable from 'material-table';
import messageStore from '../../../stores/messageStore';
import { useTranslation } from 'react-i18next';
import CarchupaPaper from '../../../components/UI/papper/CarchupaPaper';
import StateSelect from '../../../components/UX/state-select/StateSelect';
import ProjectSelect from '../../../components/UX/project-select/ProjectSelect';
import DateRangeSelect from '../../../components/UX/date-range-select/DateRangeSelect';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import settingsStore from '../../../stores/settingsStore';
import moment from 'moment';
import { LinearProgress } from '@material-ui/core';

interface LeaderboardProps extends RouteComponentProps {}

const Leaderboard = (props: LeaderboardProps) => {
  const { t } = useTranslation();
  const [dense, setDense] = useState(true);
  const [rows, setRows] = useState<LeaderboardWithFiltering[]>([]);

  const { selectedProject, selectedState, selectedPeriod } = settingsStore;
  const [loading, setloading] = useState(true);

  const headCells: Header[] = [
    {
      field: 'ID',
      title: t('ID'),
      sortable: true,
    },
    {
      field: 'firstName',
      title: t('firstName'),
      sortable: true,
    },
    {
      field: 'lastName',
      title: t('lastName'),
      sortable: true,
    },
    {
      field: 'username',
      title: t('username'),
      sortable: true,
    },
    {
      field: 'collectablesInfo.coinsCount',
      title: 'CO',
      sortable: true,
    },
    {
      field: 'collectablesInfo.blueberriesCount',
      title: 'BB',
      sortable: true,
    },
    {
      field: 'collectablesInfo.cherriesCount',
      title: 'CR',
      sortable: true,
    },
    {
      field: 'collectablesInfo.lemonsCount',
      title: 'LE',
      sortable: true,
    },
    {
      field: 'collectablesInfo.strawberriesCount',
      title: 'SB',
      sortable: true,
    },
    {
      field: 'collectablesInfo.diamondsCount',
      title: 'DI',
      sortable: true,
    },
    {
      field: 'collectablesInfo.totalCollectablesCount',
      title: t('Total Collected'),
      sortable: true,
    },
    {
      field: 'totalValue',
      title: t('Total Value'),
      sortable: true,
    },
  ];

  const loadLeaderboard = useCallback(async () => {
    const leaderboardRequest: LeaderboardRequest = {
      projectID: !!selectedProject ? selectedProject : undefined,
      countryID: !!selectedState ? selectedState : undefined,
      startDate: (function (startDate) {
        if (!!startDate) {
          const date = new Date(startDate);
          date.setHours(0);
          date.setMinutes(0);
          date.setSeconds(0);
          return moment(date).unix();
        } else {
          return undefined;
        }
      })(selectedPeriod?.startDate),
      endDate: (function (endDate) {
        if (!!endDate) {
          const date = new Date(endDate);
          date.setHours(23);
          date.setMinutes(59);
          date.setSeconds(59);
          return moment(date).unix();
        } else {
          return undefined;
        }
      })(selectedPeriod?.endDate),
    };

    const response = await API.getLeaderboardPerProjectAndCountry(leaderboardRequest);
    setloading(true);
    if (response) {
      setloading(false);
      messageStore.snackbar({
        message: `${t('Leaderboard loaded')}`,
        type: 'success',
      });
      setRows(response.data.leaderboard);
    } else {
      setloading(false);
      messageStore.snackbar({
        message: `${t('Leaderboard load failed')}`,
        type: 'error',
      });
    }
  }, [t, selectedProject, selectedState, selectedPeriod]);

  useEffect(() => {
    loadLeaderboard();
  }, [loadLeaderboard]);

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  return (
    <SimpleLayout passedStyles={styles.root}>
      {rows.length > 0 ? (
        <>
          <CarchupaPaper type="row" parentStyle={styles.paper}>
            <StateSelect allStatePlaceholder={'All states'} />
            <ProjectSelect />
            <DateRangeSelect />
          </CarchupaPaper>
          <MaterialTable
            title={t('Leaderboard')}
            columns={headCells}
            data={rows}
            options={{
              search: true,
              padding: dense ? 'dense' : 'default',
              pageSize: 5,
              pageSizeOptions: [5, 10, 15],
            }}
            components={{
              Container: (props) => <CarchupaPaper {...props} type="column" parentStyle={styles.paper} childStyle={styles.table} />,
            }}
            onChangeRowsPerPage={(rows) => {
              settingsStore.updateSelectedRowCount(rows);
            }}
          />

          <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label={t('Dense padding')} />
        </>
      ) : loading ? (
        <LinearProgress />
      ) : (
        <CarchupaPaper type="row" parentStyle={styles.paper}>
          <div>No users payments</div>
        </CarchupaPaper>
      )}
    </SimpleLayout>
  );
};

export default inject('userStore', 'messageStore', 'settingsStore')(observer(Leaderboard));
